import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SaisieDirective } from './saisie.directive';

/**
 * Service pour pouvoir accéder à la directive Saisie d'un input à partir du parent
 */
@Injectable({
  providedIn: 'root'
})
export class SaisieDirectiveService {
  private montantDir$$ = new BehaviorSubject<SaisieDirective | undefined>(undefined);
  montantDir$ = this.montantDir$$.asObservable();

  setSaisieDir(saisieDir: SaisieDirective) {
    this.montantDir$$.next(saisieDir);
  }

}

import { Component, ComponentRef, ElementRef, EventEmitter, Input, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { HistoriqueComponent } from '../../autres/historique/historique.component';
import { ImageStdComponent } from '../../autres/image-std/image-std.component';
import { SaisieMontantStdComponent } from '../../saisies/saisie-montant-std/saisie-montant-std.component';
import { BoutonComponent } from '../../../../bouton/bouton.component';
import { Atelier } from '../../../../../models/Atelier.model';
import { Logger } from '../../../../../models/Logger.model';
import { ApiImage } from '../../../../../models/ApiImage.model';
import { Router } from '@angular/router';
import { DialoguesService } from '../../../../dialogues/dialogues.service';
import { DynamiqueService } from '../../../../../services/dynamique.service';
import { WebIndexService } from '../../../../../services/web-index.service';
import { FonctionsService } from '../../../../../services/fonctions.service';
import { StandardSaisiesService } from '../../standard-saisies.service';
import { ATELIER_ID, IMAGE_COURANTE, IMAGE_SUIVANTE, NOM_PROJET } from '../../../../../constantes';
import { BoutonInfoModel } from '../../../../../models/BoutonInfo.model';
import { NgClass, NgIf } from '@angular/common';
import { routes } from '../../../../../app.routes';
import { Parametres } from '../../../../../models/Parametres.model';
import { Parametre } from '../../../../../models/Parametre.model';
import { FormsModule, NgModel } from '@angular/forms';

@Component({
  selector: 'app-atl-taylorim',
  standalone: true,
  imports: [
    NgbTooltip,
    NgIf,
    NgClass,
    FormsModule,
    HistoriqueComponent,
    ImageStdComponent,
    SaisieMontantStdComponent,
    BoutonComponent
  ],
  templateUrl: './atl-taylorim.component.html',
  styleUrl: './atl-taylorim.component.scss'
})
export class AtlTaylorimComponent {
  /** id du site choisi */
  @Input({required: true}) siteId: string = "";
  /** id du traitement choisi */
  @Input({required: true}) traitementId: string = "";
  /** id de l'atelier choisi */
  @Input({required: true}) atelier!: Atelier;
  /** Date de traitement choisi yyyyMMdd */
  @Input({required: true}) dateTraitement: string = "";   
  /** Evenement changemnet d'atelier */
  @Output() changerAtelier: EventEmitter<boolean> = new EventEmitter();
  /** Evenement pour mise à jour du stock */
  @Output() afficherStock: EventEmitter<boolean> = new EventEmitter();
  /** Evenement pour decrementer le stock */
  @Output() decStock: EventEmitter<boolean> = new EventEmitter();
  /** Evenement pour demander le stock réel restant */
  @Output() getStockReel: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('historiqueTpl', { read: ViewContainerRef, static: true})
  historiqueView!: ViewContainerRef;
  historiqueRef!: ComponentRef<any> | null;
  @ViewChild('imageTpl', { read: ViewContainerRef, static: true})
  imageView!: ViewContainerRef;
  imageRef!: ComponentRef<any> | null;
  @ViewChild('zoneSaisieTpl', { read: ViewContainerRef, static: true})
  zoneSaisieView!: ViewContainerRef;
  zoneSaisieRef!: ComponentRef<any> | null;

  logger: Logger = new Logger(this);
  image: ApiImage | null = null;  // Image en cours de saisie
  imageSuivante: ApiImage | null = null;  // Image suivante préchargée
  modeSaisie: string = "";  // M pour modification
  message: string = "";
  /** Compteur d'image saisi avant de lancer requete stock */
  cptSaisie: number = 0;

  /** Service chargé dynamiquement pour initialisation de l'ateleir */
  atlInitService: any;
  /** Fonction de traitement d'initialisation de l'atelier */
  atlInitFn: string = "";
  /** Service chargé dynamiquement pour initialisation boutons */
  initService: any;
  /** Fonction de traitement d'initialisation des boutons */
  initFn: string = "";
  /** Service chargé dynamique pour gestion des boutons */
  boutonService: any;
  /** Fonction de traitement des clic sur boutons */
  boutonFn: string = "";
  /** Service chargé dynamiquement pour gestion enregistrement */
  enregService: any;
  /** Fonction de traitement de l'enregiqtrement de la saisie */
  enregFn: string = "";

  /** Paramètres Talorim utilisé dans l'atelier */
  parametres: Map<string, string> = new Map();

  /** Objets Paramètres d'atelier chargé dans la fonction d'initialisation de l'atelier */
  objAtl: Map<string, any> = new Map();

  btnPhysique: boolean = true;
  /** Model pour switch */
  topPhysique: boolean = false;

  clsZone4: string = "";
  clsZone3: string = "";
  clsZone2: string = "";
  clsZone1: string = "";
  clsZoneRlmc: string = "";

  constructor(
    private router: Router,
    private dialoguesService: DialoguesService,
    private element: ElementRef,
    private dynamiqueService: DynamiqueService,
    private webIndexService: WebIndexService,
    private fonctionsService: FonctionsService,
    private stdSaisieService: StandardSaisiesService,
  ) { }

  ngOnInit() {
    this.logger.info("ngOnInit");
    // Préchargement du service init de l'atelier
    const { service: atlInitService, fonction: atlInitFn } = this.fonctionsService.litParametreDeService(this.atelier.fonctionAtlInit, "rienAFaire");
    this.atlInitService = atlInitService;
    this.atlInitFn = atlInitFn;
    setTimeout(() => { 
      this.fonctionsService.testServiceFonction(
        atlInitService, atlInitFn, 
        (erreur: string) => { this.affErreur(erreur) }
      )}, 0
    );

    // Préchargement du service init avant saisie
    const { service: initService, fonction: initFn } = this.fonctionsService.litParametreDeService(this.atelier.fonctionInit, "rienAFaire");
    this.initService = initService;
    this.initFn = initFn;
    setTimeout(() => { 
      this.fonctionsService.testServiceFonction(
        initService, initFn, 
        (erreur: string) => { this.affErreur(erreur) }
      )}, 0
    );

    // Préchargement du service bouton clic
    const { service: boutonService, fonction: boutonFn } = this.fonctionsService.litParametreDeService(this.atelier.fonction1, "rienAFaire");
    this.boutonService = boutonService;
    this.boutonFn = boutonFn;
    setTimeout(() => { 
      this.fonctionsService.testServiceFonction(
        boutonService, boutonFn, 
        (erreur: string) => { this.affErreur(erreur) }
      )}, 0
    );

    // Préchargement du service d'enregistrement
    const { service: enregService, fonction: enregFn } = this.fonctionsService.litParametreDeService(this.atelier.fonctionEnreg, "EnregistrerSaisie1");
    this.enregService = enregService;
    this.enregFn = enregFn;
    setTimeout(() => { 
      this.fonctionsService.testServiceFonction( 
        enregService, enregFn, 
        (erreur: string) => { this.affErreur(erreur) }
      )}, 0
    );

    this.historiqueRef = this.dynamiqueService.chargerComposant(this.atelier.param1, this.historiqueView);
    this.imageRef = this.dynamiqueService.chargerComposant(this.atelier.param2, this.imageView);
    this.imageRef!.instance.atelier = this.atelier;

    this.zoneSaisieRef = this.dynamiqueService.chargerComposant(this.atelier.param3, this.zoneSaisieView);
    if(this.zoneSaisieRef != null) {
      this.zoneSaisieRef.instance.siteId = this.siteId
      this.zoneSaisieRef.instance.traitementId = this.traitementId
      this.zoneSaisieRef.instance.atelier = this.atelier;      
      this.zoneSaisieRef.instance.dateTraitement = this.dateTraitement;      
    }

    // appel api pour recevoir les paramètres taylorim
    if(this.atelier.param10.indexOf("nomParam") > -1) {
      // Il y a des paramètres à charger
      const mParam: Map<string, string> = this.fonctionsService.str2Map(this.atelier.param10);
      let nomsParam = "";
      let sep = "";
      mParam.forEach((value, key) => {
        if(key.substring(0,8) == "nomParam") {
          nomsParam += sep + value;
          sep = "|"
        }
      });
      let aParams: [string, string][] = [];
      const mph: Map<string, string> = new Map();
      mph.set("nomsParams", nomsParam);
      this.webIndexService.appelBackendExterne(
        "getParametres", 
        this.siteId, this.traitementId, this.atelier, this.dateTraitement, 
        mph
      ).subscribe({
          error: (err) => {
            this.logger.debug("webIndexService.appelBackendExterne res", err);
            this.affErreur("Il y a eu une erreur de chargements des paramètres<br>" + err.toString());
          },
          next: (res: any) => {
            this.logger.debug("webIndexService.appelBackendExterne Parametres res", res);
            res.lstParametres.forEach((p: Parametre) => {
              this.parametres.set(p.nomParam, p.valeur);
            })        
            this.zoneSaisieRef!.instance.setParametres(this.parametres);
          },
          complete: () => { }
    
      }) ;
    }

    if(atlInitFn.length > 0) {
      atlInitService[atlInitFn](this);
    }
    this.zoneSaisieRef!.instance.objAtl = this.objAtl;

  }

  ngAfterViewInit(): void {
    this.logger.info("ngAfterViewInit()");
    this.afficherStock.emit(true);
    this.chargerImageSuivante(0, 
      (image: ApiImage) => {
        this.logger.info("ngAfterViewInit chargerImageSuivante callback", image);
        this.image = image;
        this.image!.saisieDate = this.fonctionsService.maintenant();
        this.imageRef!.instance.image = this.image;
        this.specifiqueApresChargement(IMAGE_COURANTE);
      },
      () => {
        this.stdSaisieService.afficherPlusdImageStd(this);
      }
    );   
    this.historiqueRef!.instance.onElementClick.subscribe((id: number) => {
      this.saisieModifier(id);
    });

    this.zoneSaisieRef!.instance.onImageChange.subscribe((bSuivante: boolean) => {
      if(bSuivante) {
        this.changerImage(IMAGE_SUIVANTE);
      } else {
        this.imageRef!.instance.image = this.image;
      }
    });
    this.zoneSaisieRef!.instance.onValidation.subscribe((ok: boolean) => {
      this.enregistrerSaisie();
    });
    this.zoneSaisieRef!.instance.onMessage.subscribe((message: string) => {
      this.message = message;
    });
    this.zoneSaisieRef!.instance.onEchappe.subscribe((event: string) => {
      this.changerAtelier.emit(true);
    });
    this.zoneSaisieRef!.instance.onSlash.subscribe((event: string) => {
      this.historiqueRef?.instance.clicSurDernierElement();
    });
    this.zoneSaisieRef!.instance.onError.subscribe((error: string) => {
      setTimeout( () => { this.affErreur(error); }, 0)
    });

  }

  /**
   * Appelé si erreur dans fonction d'initialisation
   */
  atlInitKo(msg: string) {
    this.affErreur(msg);
  }

  changementTopPhysique() {
    let map = this.fonctionsService.str2Map(this.image!.saisie10);
    const phys = map.get("physique");
    map.set("physique", this.topPhysique.toString());
    this.image!.saisie10 = this.fonctionsService.map2Str(map);
    if(this.topPhysique) {
      this.enregistrerSaisie();
    }
    setTimeout(() => {
      this.zoneSaisieRef?.instance.focus();  
    }, 0);

  }

  /**
   * Clic sur bouton Image suivante
   * @param event 
   */
  clicSuivante(event: MouseEvent) {
    this.stdSaisieService.imgSuivOnClicStd(this, event);
  }

  /**
   * Demande de la prochaine image libre
   * @param callBackImageOk Callback de traiement de l'image reçue
   */
  chargerImageSuivante(exclusion: number, callBackImageOk: Function, callBackImageKo: Function) {
    this.stdSaisieService.chargerImageSuivanteStd(this, exclusion, callBackImageOk, callBackImageKo);
  }

  /**
   * Changement de l'image
   * @param mode 0 = image courante, 1 = imageSuivante, 3 = abandon courante
   */
  changerImage(mode: number) {
    this.stdSaisieService.changerImageStd(this, mode);
  }

  /**
   * Enregistrement de la saisie
   */
  enregistrerSaisie() {
    this.logger.info("enregistrerSaisie");
    this.message = this.enregService[this.enregFn](this);
    this.cptSaisie ++;
    if(this.cptSaisie >= 10) {  // Rafraichissement du stock
      this.getStockReel.emit(true);
      this.cptSaisie = 0;
    }
  }

  /**
   * Callback appelé quand l'enregistrement échoue
   * @param err Callback en cas d'enregistrement ko
   */
  callBackEnregKo(err: any) {
    this.stdSaisieService.callBackEnregKoStd(this, err);
  }

  /**
   * Callback appelé quand l'enregistrement réussi
   * @param image 
   */
  callBackEnregOk(image: ApiImage) {  // TODO image vient d'ù
    this.stdSaisieService.callBackEnregOkStd(this);
  }

  /**
   * Gestion d'une demande de modification d'une image saisie
   * @param id id de l'image à modifier
   */
  saisieModifier(id: number) {
    this.stdSaisieService.saisieModifierStd(this, id);
  }
  /** 
   * Action après libération de l'image préchargée
   * @param id id de l'image à modifier
  */
  saisieModifier2(id: number) {
    this.stdSaisieService.saisieModifier2Std(this, id);
  }

  /**
   * Action après libération de l'image en cours
   * @param id id de l'image à modifier
   */
  saisieModifier3(id: number) {
    this.stdSaisieService.saisieModifier3Std(this, id);
  }

  /**
   * Libération des images chargées
   */
  imagesLiberer() {
    this.stdSaisieService.imagesLibererStd(this);
  }

  /**
   * Action après chargment de l'image: Maj boutons
   */
  specifiqueApresChargement(mode: number) {
    if(this.atelier.param10.toLowerCase().indexOf("physique=false") > -1) {
      this.btnPhysique = false;
    } else {
      this.btnPhysique = true;
    }
    let map = this.fonctionsService.str2Map(this.image!.saisie10);
    let phys = map.get("physique");
    if(phys == undefined) {
      this.topPhysique = false;
    } else {
      if(phys.toLowerCase() === "false") {
        this.topPhysique = false;
      } else {
        this.topPhysique = true;
      }
    }
    try {
      this.initService[this.initFn](this, mode);      
    } catch(error) {
      this.affErreur("Il y a un choucis dans: " + this.initService.constructor.name + "." + this.initFn + "<br>" + error);
    }
  }

  clsBtnPhysique() {
    //const cls = "btn btn-secondary btn-mini btn-gauche";
    if(this.btnPhysique) {
      return "";
    } else {
      return " btn-cache";
    }
  }

  /**
   * Affiche une erreur dans une boite de dialogue
   * @param erreur 
   */
  affErreur(erreur: string) {
    this.dialoguesService.messageBox(
      NOM_PROJET,
      "Oups ! Houston, on a un problème: <br>" + erreur,
      () => { 
        this.imagesLiberer();
        this.router.navigate(["choix"]);    
      },
      -1
    );
  }
}

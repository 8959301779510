import { Component, Input, input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Atelier } from '../../../../../models/Atelier.model';
import { ApiImage } from '../../../../../models/ApiImage.model';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-image-std',
  standalone: true,
  imports: [ NgbTooltip],
  templateUrl: './image-std.component.html',
  styleUrl: './image-std.component.scss'
})
export class ImageStdComponent {
  @Input() atelier: Atelier | null = null;
  @Input() image!: ApiImage | null;
  
  constructor( ) { 
  }

  OnInit(): void {
  }

}

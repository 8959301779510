import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewContainerRef } from '@angular/core';
import { BoutonComponent } from '../../bouton/bouton.component';

@Component({
  selector: 'app-titre-std',
  standalone: true,
  imports: [BoutonComponent],
  templateUrl: './titre-std.component.html',
  styleUrl: './titre-std.component.scss'
})
export class TitreStdComponent implements OnInit {
  @Input() titre: string = "";

  constructor(
    private chgDetector: ChangeDetectorRef,
    //public viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.chgDetector.detectChanges();
  }
}

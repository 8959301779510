import { Injectable } from '@angular/core';
import { FonctionsService } from '../../../../services/fonctions.service';
import { WebIndexService } from '../../../../services/web-index.service';
import { StandardTaylorimService } from '../../standard/standard-taylorim.service';
import { ControleSaisieStdService } from '../../standard/controle-saisie-std.service';
import { TYPEREM, TYPETKL } from '../../standard/ateliers/atl-taylorim/TimCconstantes';
import { OPERATEUR } from '../../../../constantes';
import { LbpSaisiesService } from './lbp-saisies.service';

@Injectable({
  providedIn: 'root'
})
export class LbpSaisiesCmc7Service {

  [key: string]: any; // Signature pour appel methode par tableau, pour paramétrage dans Atelier

  constructor(
    private fonctionsService: FonctionsService,
    private webIndexService: WebIndexService,
    private standardTaylorimService: StandardTaylorimService,
    private controleSaisieStdService: ControleSaisieStdService,
    private lbpSaisiesService: LbpSaisiesService,
  ) { }

  /*===========================================================================
    Fonctions pour ateliers saisie cmc7
  =============================================================================*/
  cmc7AtlInit(objAtlSaisie: any) {
    // Chargement codes centres Lbp
    let mapParam = new Map<string, string>();
    mapParam.set("codeClient", objAtlSaisie.atelier.param9);

    this.webIndexService.appelBackendExterne(
      "getAgences", 
      objAtlSaisie.siteId, objAtlSaisie.traitementId, objAtlSaisie.atelier, 
      objAtlSaisie.dateTraitement, 
      mapParam
    ).subscribe({
        error: (err) => {
          objAtlSaisie.atlInitKo("Oups!  Erreur " + err.status + " dans l'appel de " + err.url);
        },
        next: (res: any) => {
          console.log("-------- centres", res)
          if(res.length == 0) {
            objAtlSaisie.atlInitKo("Oups!  Erreur de chargment des centres pour " + objAtlSaisie.atelier.param9);
          } else {
            objAtlSaisie.objAtl.set("lstCentres", res)
          }
        },
        complete: () => { }  
    }) ;
  }

  /**
   * Calcul du focus suivant
   * @param objZoneSaisieRef Objet ZoneSaisieRef
   * @param noZone Numéro de la zone qui a le focus
   * @returns 
   */
  cmc7Focus(objZoneSaisieRef: any, noZone: number): boolean {
    objZoneSaisieRef.typeDocument = this.standardTaylorimService.typeDocument(objZoneSaisieRef);
    switch(noZone) {
      case 0: // Entrée en saisie
        if(objZoneSaisieRef.saisie4.indexOf("?") > -1) {
          setTimeout( () => { objZoneSaisieRef.inputSaisie4.nativeElement.focus() }, 0);
        } else {
          if(objZoneSaisieRef.saisie4.length == 7) {
            return this.cmc7Focus(objZoneSaisieRef, 4);
          } else {
            setTimeout( () => { objZoneSaisieRef.inputSaisie4.nativeElement.focus() }, 0);
          }
        }
        return false;

      case 4:
        if(objZoneSaisieRef.saisie4.indexOf("?") > -1) {
          setTimeout( () => {
            objZoneSaisieRef.inputSaisie4.nativeElement.blur();
            objZoneSaisieRef.inputSaisie4.nativeElement.focus();  
          }, 0)
          return false;
        }
        if(objZoneSaisieRef.saisie3.indexOf("?") > -1) {
          setTimeout( () => { objZoneSaisieRef.inputSaisie3.nativeElement.focus() }, 0);
        } else {
          if(objZoneSaisieRef.saisie3.length == 12) {
            return this.cmc7Focus(objZoneSaisieRef, 3);
          } else {
            setTimeout( () => { objZoneSaisieRef.inputSaisie2.nativeElement.focus() }, 0);
          }
        }
        return false;

      case 3:
        if(objZoneSaisieRef.saisie3.indexOf("?") > -1) {
          setTimeout(() => {
            objZoneSaisieRef.inputSaisie3.nativeElement.blur();
            objZoneSaisieRef.inputSaisie3.nativeElement.focus();              
          }, 0);
          return false;
        }
        if(objZoneSaisieRef.typeDocument == TYPEREM && this.typeRem(objZoneSaisieRef) == "B") {
          objZoneSaisieRef.saisie2 = "";
          return true;
        } else {
          if(objZoneSaisieRef.saisie2.indexOf("?") > -1) {
            setTimeout( () => { objZoneSaisieRef.inputSaisie2.nativeElement.focus(); }, 0)
          } else {
            if(objZoneSaisieRef.saisie2.length == 12) {
              return this.cmc7Focus(objZoneSaisieRef, 2);
            } else {
              setTimeout( () => { objZoneSaisieRef.inputSaisie2.nativeElement.focus() }, 0);
            }
          }
          return false;
        }

      case 2:
        if(objZoneSaisieRef.saisie2.indexOf("?") > -1) {
          setTimeout( () => {
            objZoneSaisieRef.inputSaisie2.nativeElement.blur();
            objZoneSaisieRef.inputSaisie2.nativeElement.focus();  
          }, 0)
          return false;
        }
        if(objZoneSaisieRef.typeDocument == TYPETKL || objZoneSaisieRef.typeDocument == TYPEREM) {
          return true;
        } else {
          if(objZoneSaisieRef.saisie1 && objZoneSaisieRef.saisie1.length > 0) {
            setTimeout( () => { objZoneSaisieRef.inputSaisie1.nativeElement.focus() }, 0);
          } else {            
            setTimeout( () => { objZoneSaisieRef.inputSaisie9.nativeElement.focus() }, 0);
          }
          return false;
        }

      case 1:
        if(objZoneSaisieRef.saisie1.indexOf("?") > -1) {
          setTimeout( () => {
            objZoneSaisieRef.inputSaisie1.nativeElement.blur();
            objZoneSaisieRef.inputSaisie1.nativeElement.focus();  
          }, 0)
          return false;
        }
        setTimeout( () => { objZoneSaisieRef.inputSaisie9.nativeElement.focus() });
        return false;
      case 9:
        return true;
    }
    return false;
  }


  /**
   * Enregistrment de la saisie cmc7
   * @param objAtlSaisie Composant atelier de saisie
   * @returns 
   */
  cmc7Enregistrer(objAtlSaisie: any) {
    let message = "";
    if(objAtlSaisie.image != null) {
      objAtlSaisie.image.saisie4 = objAtlSaisie.zoneSaisieRef!.instance.saisie4;
      objAtlSaisie.image.saisie3 = objAtlSaisie.zoneSaisieRef!.instance.saisie3;
      objAtlSaisie.image.saisie2 = objAtlSaisie.zoneSaisieRef!.instance.saisie2;
      objAtlSaisie.image.saisie1 = objAtlSaisie.zoneSaisieRef!.instance.saisie1;
      objAtlSaisie.image.saisie5 = objAtlSaisie.zoneSaisieRef!.instance.typeDocument;
      objAtlSaisie.image.saisie8 = objAtlSaisie.zoneSaisieRef!.instance.saisie8;
      objAtlSaisie.image.saisie9 = objAtlSaisie.zoneSaisieRef!.instance.saisie9;
      objAtlSaisie.image.operateur = sessionStorage.getItem(OPERATEUR) ?? "";
      objAtlSaisie.image.saisieEnregDate = this.fonctionsService.maintenant();
      this.webIndexService["enregistrer"](objAtlSaisie, objAtlSaisie.modeSaisie);
    } else {
      message = "Image à enregister null ?";
    }
    return message;
  }

  /**
   * Calcule le libéllé affiché dans historique
   * @param objZoneSaisieRef 
   * @returns Libellé affiché
   */
  cmc7Historique(objZoneSaisieRef: any): string {
    return this.standardTaylorimService.libCodeEnreg(objZoneSaisieRef.saisie5) + "  "
      + objZoneSaisieRef.saisie4 + " " + objZoneSaisieRef.saisie3 + " " 
      + objZoneSaisieRef.saisie2 + " [" + objZoneSaisieRef.saisie9 + "] " 
      + (objZoneSaisieRef.saisie8 == "1" ? " F" : " ");
  }


  typeRem(objZoneSaisieRef: any) {
    // On suppose que c'est typé remise
    if(objZoneSaisieRef.saisie3.substring(0, 6) == "999999") {
      return "B";
    } else {
      return "P";
    }
  }
  
  /*===========================================================================
    Ateliers Zone2 inconnu
  =============================================================================*/
  /**
   * Initialisation des champs chqLbp
   * @param objAtlSaisie 
   */
  chqLbpInit(objAtlSaisie: any) {
    objAtlSaisie.zoneSaisieRef!.instance.saisie4 = objAtlSaisie.image.saisie4;
    objAtlSaisie.zoneSaisieRef!.instance.saisie3 = objAtlSaisie.image.saisie3;
    objAtlSaisie.zoneSaisieRef!.instance.saisie2 = objAtlSaisie.image.saisie2;
    objAtlSaisie.zoneSaisieRef!.instance.saisie1 = objAtlSaisie.image.saisie1;
    objAtlSaisie.zoneSaisieRef!.instance.saisie9 = objAtlSaisie.image.saisie9;
    objAtlSaisie.zoneSaisieRef!.instance.saisie5 = objAtlSaisie.image.saisie5;
    objAtlSaisie.zoneSaisieRef!.instance.saisie8 = objAtlSaisie.image.saisie8;
    objAtlSaisie.zoneSaisieRef!.instance.nbSaisieCle = 0;
    objAtlSaisie.zoneSaisieRef!.instance.saisie9Prc = "";

    this.chqLbpFocus(objAtlSaisie.zoneSaisieRef.instance, 0);
  }

  /**
   * Calcul du focus suivant
   * @param objZoneSaisieRef Objet ZoneSaisieRef
   * @param noZone Numéro de la zone qui a le focus
   * @returns 
   */
  chqLbpFocus(objZoneSaisieRef: any, noZone: number): boolean {
    switch(noZone) {
    case 0: // Entrée en saisie
      setTimeout(() => {
        objZoneSaisieRef.inputSaisie2.nativeElement.select();
        objZoneSaisieRef.inputSaisie2.nativeElement.focus();        
      }, 0);
      return false;

    case 2:
      this.ctrlChqLbp(objZoneSaisieRef);
      return false;
  
    case 9:
      if((objZoneSaisieRef.saisie9 ?? "").length == 2) {
        return true;
      }
    }
    return false;
  }

  /**
   * Calcule le libéllé affiché dans historique
   * @param objZoneSaisieRef 
   * @returns Libellé affiché
   */
  chqLbpHistorique(objZoneSaisieRef: any): string {
    return objZoneSaisieRef.saisie2 + " [" + objZoneSaisieRef.saisie9 + "] " 
      + (objZoneSaisieRef.saisie8 == "1" ? " F" : " ");
  }

  /**
   * Contrôle la zone2 saisie
   * @param objZoneSaisieRef Composant contenant les champs de saisie
   * @returns 
   */
  ctrlChqLbp(objZoneSaisieRef: any) {
    this.lbpSaisiesService.lbpZone2EstValide(
      objZoneSaisieRef, 
      objZoneSaisieRef.saisie2,
      objZoneSaisieRef.objAtl.get("lstCentres"),
      () => { 
        if(objZoneSaisieRef.saisie9 != null && objZoneSaisieRef.saisie9.length == 2) {
          this.controleSaisieStdService.ctrlCmc7(objZoneSaisieRef);
        } else {
          setTimeout( () => { 
            objZoneSaisieRef.inputSaisie9.nativeElement.focus() }, 0);
        }
      },
      (msg: string) => { objZoneSaisieRef.saisieKo(msg); }
    );
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { FonctionsService } from '../../../../services/fonctions.service';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-age',
  standalone: true,
  imports: [NgbTooltip],
  templateUrl: './age.component.html',
  styleUrl: './age.component.scss'
})
export class AgeComponent implements OnInit {
  @Input({ required: true }) date: string = "";

  age: number = 0;
  classes: string = "";
  icone: string = "";
  tooltipDate: string = "";
  constructor(
    private fonctionsService: FonctionsService,
  ) {}

  ngOnInit(): void {
    this.age = Math.round(this.fonctionsService.calculerNbHeures(this.fonctionsService.yyyyMMddHHmmss2Date(this.date)));
    this.tooltipDate = "Image la moins récente: " + this.fonctionsService.afficherDateHeure(this.date);
    if(this.age > 12) {
      this.classes = "badge badge-pill badge-noir"      
      this.icone = "/assets/images/horloge5.png"      
    } else if(this.age > 6) {
      this.classes = "badge badge-pill badge-critique"      
      this.icone = "/assets/images/horloge4.png"      
    } else if(this.age > 4) {
      this.classes = "badge badge-pill badge-danger"      
      this.icone = "/assets/images/horloge3.png"      
    } else if(this.age >= 2) {
      this.classes = "badge badge-pill badge-warning"      
      this.icone = "/assets/images/horloge2.png"      
    } else {
      this.classes = "badge badge-pill badge-success"
      this.icone = "/assets/images/horloge1.png"      
    }
  }


}



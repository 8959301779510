import { Injectable } from '@angular/core';
import { FonctionsService } from './fonctions.service';
import { environment } from '../../environments/environment';
/**
 * Service pour stocker le domaine d'appel pour gestion url api
 */
@Injectable({
  providedIn: 'root'
})
export class ApiUrlService {
  urlDomaine: string = "";

  constructor(
    private fonctionsService: FonctionsService,
  ) { 
    const map: { [key: string]: string } = environment.webindexApiUrlBases;
    const url = window.location.href.replace("//", "/");
    const au = url.split("/");
    let cle: string = "";
    if(au.length > 0) {
      cle = au[0] + "//" + au[1];
    }
    this.urlDomaine = map[cle];
  }
}

<div class="">
  <span class="" ngbDropdownToggle>{{ titre }}</span>
</div>
<div ngbDropdownMenu aria-labelledby="dropdownBasic1">  
  <button *ngFor="let element of liste"
    ngbDropdownItem 
    (change)="libelleChange($event)"
  >
    <input class="form-check-input" type="checkbox" [value]="element.id" id="{{ idHtml + element.id }}" 
      [checked]="checked(element)"
      (change)="libelleChange($event)"
    >
    <label class="form-check-label" for="{{ idHtml + element.id }}">
      {{ element.id + " " + element.libelle}}
    </label>              
  </button>
</div>

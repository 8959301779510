<div>
  <div class="modal-header">
    <template #titreComponent></template>
    <button
      type="button"
      class="modal-close close"
      aria-label="Close"
      (click)="fermer('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <template #corpsComponent></template>
  </div>
  <div class="modal-footer">
    <template #btnsComponent></template>
  </div>
</div>

import { Injectable } from '@angular/core';
import { FonctionsService } from '../../../services/fonctions.service';
import { StandardSaisiesService } from './standard-saisies.service';
import { DialoguesService } from '../../dialogues/dialogues.service';
import { NOM_PROJET } from '../../../constantes';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TYPECHQ } from './ateliers/atl-taylorim/TimCconstantes';

/**
 * Service regroupant les fonctions de contrôle de saisie standard
 */
@Injectable({
  providedIn: 'root'
})
export class ControleSaisieStdService {

  constructor(
    private fonctionsService: FonctionsService,
    private dialoguesService: DialoguesService,
    private standardSaisieService: StandardSaisiesService,
  ) { }

  [key: string]: any; // Signature pour appel methode par tableau, pour paramétrage dans Atelier

  /**
   * Contrôle de saisie de montant pas vide
   * @param objZoneSaisieRef Composant appelant
   * @param montant Montant saisi
   * @returns Message d'erreur s'il y a, "" sinon
   */
  ctrlMontantStd(objZoneSaisieRef: any, montant: string): string {
    // numérique
    if(montant.length == 0) {
      return "Veuillez saisir un montant";
    }
    if(isNaN(Number(montant))) {
      return "Le montant saisi n'est pas numérique";
    }
    return "";
  }

  /**
   * Contrôle de saisie de montant pas vide, 1 sinon
   * @param objZoneSaisieRef Composant appelant
   * @param montant Montant saisi
   * @returns Message d'erreur s'il y a, "" sinon
   */
  ctrlMontantStd1(objZoneSaisieRef: any, montant: string): string {
    // numérique
    if(montant.length == 0) {
      return "Veuillez saisir un montant, ou 1 à défaut";
    }
    if(isNaN(Number(montant))) {
      return "Le montant saisi n'est pas numérique";
    }
    if(Number(montant) == 0) {
      return "Veuillez saisir un montant non null";
    }
    return "";
  }

  /**
   * Contrôle de saisie de montant pas vide, 2 sinon
   * @param objZoneSaisieRef Composant appelant
   * @param montant Montant saisi
   * @returns Message d'erreur s'il y a, "" sinon
   */
  ctrlMontantStd2(objZoneSaisieRef: any, montant: string): string {
    // numérique
    if(montant.length == 0) {
      return "Veuillez saisir un montant, ou 2 à défaut";
    }
    if(isNaN(Number(montant))) {
      return "Le montant saisi n'est pas numérique";
    }
    if(Number(montant) == 0) {
      return "Veuillez saisir un montant non null";
    }
    return "";
  }

  /**
   * Controle de vérification montant avec 2 ctmes pour non saisie
   * @param objZoneSaisieRef Composant de saisie
   * @param montant 
   * @returns 
   */
  ctrlVerifMontantStd2(objZoneSaisieRef: any, montant: string): string {
    if(objZoneSaisieRef.montantPrec == montant) {
      return "";
    } else {
      objZoneSaisieRef.montantPrec = montant;
      objZoneSaisieRef.razSaisie("");
      return "Veuillez resaisir le montant, ou 2 cent";
    }
  }

  /**
   * Controle de saisie date jjMMyy et heure HHmm
   * @param objZoneSaisieRef Doit utiliser les attributs dateSaisie et heureSaisie
   * @returns 
   */
  ctrlDateEtHeure(objZoneSaisieRef: any): string {
    if(objZoneSaisieRef.dateSaisie.length == 6
        && this.fonctionsService.ddMMaaEstDate(objZoneSaisieRef.dateSaisie)) {
      if(objZoneSaisieRef.heureSaisie.length == 4
          && this.fonctionsService.hhmmEstHeure(objZoneSaisieRef.heureSaisie)) {
        return "";
      } else {
        return "L'heure n'est pas valide"
      }
    } else {
      return "La date n'est pas valide"
    }
  }

  /**
   * Controle la ligne cmc7
   * @param objZoneSaisieRef Composant dans zoneSaisieRef
   * @returns 
   */
  ctrlCmc7(objZoneSaisieRef: any) {
    const cmc7 = this.fonctionsService.padL(objZoneSaisieRef.saisie4, 7, "0")
          + this.fonctionsService.padL(objZoneSaisieRef.saisie3, 12, "0")
          + this.fonctionsService.padL(objZoneSaisieRef.saisie2, 12, "0");
          
    if((cmc7 + objZoneSaisieRef.saisie1).indexOf("?") > -1) {
      objZoneSaisieRef.focusService[objZoneSaisieRef.focusFn](this, 0);
      objZoneSaisieRef.saisieKo("Caractère non corrigé");
    }
    if(objZoneSaisieRef.typeDocument == TYPECHQ) {
      if(this.fonctionsService.cleRLMC(cmc7) != this.fonctionsService.padL(objZoneSaisieRef.saisie9, 2, "0") ) {
        objZoneSaisieRef.nbSaisieCle ++;
        if(objZoneSaisieRef.nbSaisieCle < 3) {
          objZoneSaisieRef.inputSaisie9.nativeElement.setSelectionRange(0, 2);
          objZoneSaisieRef.saisieKo("La clé Rlmc ne correspond pas");
        } else {
          if(objZoneSaisieRef.saisie9Prec != objZoneSaisieRef.saisie9) {
            objZoneSaisieRef.saisie9Prec = objZoneSaisieRef.saisie9;
            objZoneSaisieRef.saisie9 = ""; // Vidage pour resaisie
            objZoneSaisieRef.saisieKo("Veuillez saisir la clé fausse pour confirmer");
          } else {
            objZoneSaisieRef.saisie8 = "1";
            objZoneSaisieRef.saisieOk();
          }
        }
      } else {
        objZoneSaisieRef.saisie8 = "0";
        objZoneSaisieRef.saisieOk();
      }
    } else {
      objZoneSaisieRef.saisie8 = "";
      objZoneSaisieRef.saisieOk();
    }
  }
}

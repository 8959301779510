
/**
 * Classe pour manipuler les informations du modèle IdentModel reçu de l'api
 */
export class Identification {
    private apiKey: string;
    private login: string;
    private nom: string;
    private prenom: string;
    private roles: string[];

    constructor(
        identification: any // Objet JSON reçu
    ){
        this.apiKey = identification.apiKey;
        this.login = identification.login;
        this.nom = identification.nom;
        this.prenom = identification.prenom;
        this.roles = identification.roles;
    }

    public getApiKey(): string {
        return this.apiKey;
    }

    public getLogin(): string {
        return this.login;
    }

    public getNom(): string {
        return this.nom;
    }

    public getPrenom(): string {
        return this.prenom;
    }

    public getRoles(): string[] {
        return this.roles;
    }

}

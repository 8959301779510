import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BnppFonctionsService {

  constructor() { }

  [key: string]: any; // Signature pour appel methode par tableau, pour paramétrage dans Atelier

  ctrlCompte(noCompte: string): string {
    // numérique
    // TODO PAD automatique
    if(noCompte.length != 12) {
      return "Veuillez saisir un compte sur 12 caractères AAAAnnnnnnnn ou vide pour passer";
    }
    if(isNaN(Number(noCompte))) {
      return "Le compte doit être numérique";
    }
    return "";
  }

}

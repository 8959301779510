import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewContainerRef } from '@angular/core';
import { DlgParams } from '../dlg-params';
import { CommonModule } from '@angular/common';
import { fromEvent, Subscription } from 'rxjs';

/**
 * Composant pour afficher un bouton Fermer au bas d'un dialogue
 */
@Component({
  selector: 'app-dlg-fermer-boutons',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dlg-fermer-boutons.component.html',
  styleUrls: ['./dlg-fermer-boutons.component.scss']
})
export class DlgFermerBoutonsComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input({required: true }) public dlgParams!: DlgParams;
  @Output() btnEventEmit = new EventEmitter<string>();

  dlgSubscription!: Subscription; // pour touche raccourcis dilaogue

  constructor(
    public viewContainerRef: ViewContainerRef
  ) { }

  ngOnInit(): void {
    this.dlgSubscription = fromEvent(document, "keyup").subscribe( 
      event => this.fermer()
    );

  }

  ngAfterViewInit(): void {
    if(this.dlgParams.getParam("timeout") > 0)  {
      setTimeout( () => { this.fermer() }, this.dlgParams.getParam("timeout"));
    }
  }

  ngOnDestroy(): void {
    this.dlgSubscription.unsubscribe();    
  }

  fermer() {
    this.btnEventEmit.emit('close');
  }

}

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

/**
 * Composant d'affichage de nombre sous forme de badge, avec couleur de fond caculé par rapport à un pourcentage
 */
@Component({
  selector: 'app-stock-pill',
  standalone: true,
  imports: [],
  templateUrl: './stock-pill.component.html',
  styleUrl: './stock-pill.component.scss'
})
export class StockPillComponent implements OnInit {
  /** Nombre d'images à saisir */
  @Input() nbASaisir: number = 0;
  /** Nombre d'images à saisir en stock */
  @Input() stockMax: number = 0;
  /** True pour inverser les tests sur pourcentage */
  @Input() inverser: boolean = false;
  /** Jeu de classe de couleurs à utiliser: 1 = standard, 2 = standard inversé */
  @Input() jeuClasses: number = 1;

  classes: string = "";

  ngOnInit(): void {
      
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.classes = this.calculerBadgeClass();
  }

  /**
   * Calcul des classes à apposer
   * @param stock nb à saisir
   * @returns 
   */
  calculerBadgeClass() {
    let cls1: string;
    let cls2: string;
    let cls3: string;
    let cls4: string;
    let pc = 100;
    if(this.stockMax != 0) {
      pc = Math.round(this.nbASaisir * 100 / this.stockMax);
    }
    switch(this.jeuClasses) {
      case 2: 
      cls4 = "badge badge-stock badge-danger badge-pill";
      cls3 = "badge badge-stock badge-warning badge-pill";
      cls2 = "badge badge-stock badge-primary badge-pill";
      cls1 = "badge badge-stock badge-success badge-pill";
      break;
    default:
      cls1 = "badge badge-stock badge-danger badge-pill"
      cls2 = "badge badge-stock badge-warning badge-pill"
      cls3 = "badge badge-stock badge-primary badge-pill"
      cls4 = "badge badge-stock badge-success badge-pill";
    }
    if(!this.inverser) {
      if(pc > 75) {
        return cls1;
      } else if(pc > 50) {
        return cls2;
      } else if(pc > 25) {
        return cls3;
      } else {
        return cls4;
      }
    } else {
      if(pc > 75) {
        return cls4;
      } else if(pc > 50) {
        return cls3;
      } else if(pc > 25) {
        return cls2;
      } else {
        return cls1;
      }    
    }

  }


}

import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IdLibelle } from '../../../../models/IdLibelle.model';
import { CommonModule } from '@angular/common';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-sel-libelle',
  standalone: true,
  templateUrl: './sel-libelle.component.html',
  styleUrl: './sel-libelle.component.scss',
  imports: [
    CommonModule,
    NgbDropdown,
    NgbDropdownItem,
    NgbDropdownToggle,
    NgbDropdownMenu,
],
  providers: [
    NgbDropdown
],

})
export class SelLibelleComponent {
  @Input({ required: true }) idHtml: string = "";
  @Input({ required: true }) titre: string = "";
  @Input() liste: IdLibelle[] = [];
  @Input() filtre: Set<string> = new Set();

  @Output() filtreChange: EventEmitter<Set<string>> = new EventEmitter<Set<string>>();

  checked(idLibelle: IdLibelle) {
    if(this.filtre.has(idLibelle.id)) {
      return true;
    } else {
      return false;
    }
  }

  libelleChange(event: Event) {
    const checkbox = event.target as HTMLInputElement;
    if(checkbox.checked) {
      // on ajoute dans le filtre
      this.filtre.add(checkbox.value);
    } else {
      // on retire du filtre
      this.filtre.delete(checkbox.value);
    }
    this.filtreChange.emit(this.filtre)
  }

}

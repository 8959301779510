import { Injector, Pipe, PipeTransform } from '@angular/core';
import { LowerCasePipe, UpperCasePipe } from '@angular/common';

/**
 * Pipe dynamique pour pouvoir choisir un pipe en fonction de son nom
 */
@Pipe({
  name: 'dynamicPipe',
  standalone: true
})
export class DynamicPipePipe implements PipeTransform {
  constructor(private injector: Injector) {}

  transform(value: string, nomPipe: string): string {
    // Si pipeName est 'none' ou vide, ne pas appliquer de pipe
    if (!nomPipe || nomPipe === 'none') {
      return value;
    }
    let pipe: PipeTransform;
    switch (nomPipe) {
      case 'majuscule':
        pipe = this.injector.get(UpperCasePipe);
        break;
      case 'minuscule':
        pipe = this.injector.get(LowerCasePipe);
        break;
      default:
        return value;
    }
    return pipe.transform(value);
  }

}

/**
 * Constantes pour atelier Taylorim
 */
/** CodeEnreg Ticket lot */
export const TYPETKL = "2";
/** CodeEnreg Remise */
export const TYPEREM = "4"; 
/** CodeEnreg Separateur */
export const TYPESEP = "3"; 
/** CodeEnreg Chèques */
export const TYPECHQ = "6";  

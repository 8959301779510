import { Directive, ElementRef, EventEmitter, HostListener, Inject, Input, OnInit, Output, inject } from '@angular/core';
import { FonctionsService } from '../../services/fonctions.service';
import { Logger } from '../../models/Logger.model';
import { SaisieDirectiveService } from './saisie-directive.service';
import { tap, withLatestFrom } from 'rxjs';
import { PARENT_COMPONENT_TOKEN, StandardSaisieInterface } from '../ateliers/standard/standard-saisie-interface';

/**
 * Directive pour input de saisie standard, sans masque ou ajout de caractères
 *   avec double, triple 0
 */
@Directive({
  selector: '[saisieDirective]',
  standalone: true
})
export class SaisieDirective {
  saisieDirService = inject(SaisieDirectiveService, {optional: true});

  @Input() anticipation: number = -1; // Nb de caractère avant chargement img suivante
  @Input() maxLength: number = 12; // Longueur max
  @Input() type: string = "9"; // 9 = Numérique, A = Alpha, M = Alpha majuscule, m = Alpha minuscule, 
                                // TODO Masque
  @Output() yaSaisie: EventEmitter<boolean> = new EventEmitter();
  @Output() imageSuivante: EventEmitter<boolean> = new EventEmitter();
  @Output() valider: EventEmitter<string> = new EventEmitter();
  @Output() echappe: EventEmitter<string> = new EventEmitter();

  logger: Logger = new Logger(this);
  constructor(
    private elementRef: ElementRef,
    @Inject(PARENT_COMPONENT_TOKEN) private parent: StandardSaisieInterface,
    private fonctionsService: FonctionsService
  ) {
    if(this.saisieDirService) {  // Enregistrement de la directive dans le service qui l'expose
      this.saisieDirService.setSaisieDir(this);
    }
  }

  @HostListener('keydown', ['$event']) 
  onkeyDown(event: KeyboardEvent) {
    this.logger.info("onkeyDown", event);
    if(event.key == "Enter") {
      this.traiterEnter(event);
    } else if(event.key == "Escape") {
      this.traiterEchap(event);
    } else if(event.key == "+") {
      this.traiterZero(event, 3);
    } else if(event.key == ".") {
      this.traiterZero(event, 2);
    } else {
    }
  
  }

  @HostListener('keypress', ['$event']) 
  onKeyPress(event: KeyboardEvent) {
    this.logger.info("onKeyPress", event);
    if(event.key == "Enter") {
      event.preventDefault();
      event.stopPropagation();
    } else if(event.key == "*") {
      this.elementRef.nativeElement.value = "";
      event.preventDefault();
      event.stopPropagation();
      this.imageSuivante.emit(false);
    } else {
      if(this.elementRef.nativeElement.value.length < this.maxLength) {
        if(this.type == "9") {
          if("0123456789".indexOf(event.key) < 0) { 
            event.preventDefault();
            event.stopPropagation();
            return
          } 
        } else if(this.type == "A" || this.type == "M" || this.type == "m") {
          if(!this.fonctionsService.estAlphabetique(event.key)) {
            event.preventDefault();
            event.stopPropagation();      
            return
          }
        }
        if(this.type == "M") {
          this.elementRef.nativeElement.value = this.elementRef.nativeElement.value.toUpperCase();
        } else if(this.type == "m") {
          this.elementRef.nativeElement.value = this.elementRef.nativeElement.value.toLowerCase();
        }

        let curPosNew: number = 0;
        let curPos: number = (this.elementRef?.nativeElement?.selectionStart) != null ? this.elementRef.nativeElement.selectionStart : 0;
        let valueLength: number = this.elementRef.nativeElement.value.length;
        if(curPos == valueLength) {
          this.elementRef.nativeElement.value += event.key;
          curPosNew = valueLength + 1;
        } else {
          this.elementRef.nativeElement.value = this.elementRef.nativeElement.value.slice(0, curPos) + event.key + this.elementRef.nativeElement.value.slice(curPos);
          curPosNew = curPos + 1;
        }

        this.mettreAjour(event, curPosNew);          
        valueLength = this.elementRef.nativeElement.value.length;
        if(valueLength == 0) {
          this.yaSaisie.emit();
        }
      } else {
        event.preventDefault();
        event.stopPropagation();
      }
    }
  }

  @HostListener('keyup', ['$event']) 
  onkeyUp(event: KeyboardEvent) {
    this.logger.info("onkeyUp", event);
    if(event.key == "Enter" || event.key == "Escape" 
        || event.key == "+" || event.key == ".") {
      // on ignore car traité dans le down
      event.preventDefault();
      event.stopPropagation();
    }
  }

  /**
   * Traitement de la touche Echap
   * @param event 
   */
  traiterEchap(event: KeyboardEvent) {
    this.logger.info("traiterEchap", event);
    event.preventDefault();
    event.stopPropagation();
    this.echappe.emit("");
  }

  /**
   * Traitement de la touche Entrée
   * @param event 
   */
  traiterEnter(event: KeyboardEvent) {
    this.logger.info("traiterEnter", event);
    this.valider.emit(this.elementRef.nativeElement.value);
  }

  /**
   * Traitement des touches . et + pour ajouter des 0
   * @param event 
   * @param nb 
   */
  traiterZero(event: KeyboardEvent, nb: number) {
    this.logger.info("traiterZero", [event, nb]);
    for(let i = 1; i <= nb; i++) {
      let curPosNew: number = 0;
      let curPos: number = (this.elementRef?.nativeElement?.selectionStart) != null ? this.elementRef.nativeElement.selectionStart : 0;
      let valueLength: number = this.elementRef.nativeElement.value.length;
      if(curPos == valueLength) {
        this.elementRef.nativeElement.value += "0";
        curPosNew = this.elementRef.nativeElement.value.length;
        if(this.elementRef.nativeElement.value.length > 2) {
          curPosNew += nb
        }
      } else if(curPos < valueLength - 2) {
        //this.montant = this.montant.slice(0, curPos) + "0" + this.montant.slice(curPos);
        curPosNew = curPos + nb;
      } else if(curPos >= valueLength - 2) {
        //this.montant = this.montant.slice(0, curPos - 1) + "0" + this.montant.slice(curPos - 1);
        curPosNew = curPos + nb;
      }
      this.mettreAjour(event, curPosNew);
    }
  }

  /**
   * Mise à jour de la valeur de l'input et arrêt event
   * @param event event traité
   * @param curPosNew Nouvelle position sur curseur ou -1 = inchangée
   */
  mettreAjour(event: KeyboardEvent, curPosNew: number) {
    this.logger.info("mettreAjour", [event, curPosNew]);
    event.preventDefault();
    event.stopPropagation();
    this.elementRef.nativeElement.selectionStart = curPosNew;
    this.elementRef.nativeElement.selectionEnd = curPosNew;
    // export de la valeur
    this.elementRef.nativeElement.setAttribute("data-saisie", this.elementRef.nativeElement.value);
    // Gestion anticipation affichage image
    if(this.anticipation > -1 && this.elementRef.nativeElement.value.length == this.anticipation + 1) {
      this.logger.info("anticipation enclenchée", [this.anticipation, this.elementRef.nativeElement.value.length])
      // Chargement image suivante
      this.imageSuivante.emit(true);
    } else if(this.elementRef.nativeElement.value.length == this.anticipation) {
      // Reaffichage image en cours
      this.imageSuivante.emit(false);
    }
  }

  /**
   * Réinitialisation du input contenant la directive
   */
  razSaisie() {
    this.elementRef.nativeElement.value = "";
    this.parent.saisie1 = "";
  }


}
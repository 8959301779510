<div class="boutons">
  <button #btn1 id="btn1" type="button" class="btn" 
    [ngClass]="{'btn-primary': this.dlgParams.getParam('defaut') == 1, 'btn-secondary': this.dlgParams.getParam('defaut') == 2 }" 
    (click)="btnClick(1)"
  >
    {{ dlgParams.getParam("btnLib1") }}
  </button>
  <button #btn2 id="btn2" type="button" class="btn" 
    [ngClass]="{'btn-primary': this.dlgParams.getParam('defaut') == 2, 'btn-secondary': this.dlgParams.getParam('defaut') == 1 }"
    (click)="btnClick(2)"
  >
  {{ dlgParams.getParam("btnLib2") }}
</button>
</div>
<div class="container-fluid">
  <div class="row align-items-start">
    <!-- Zone de la colonne à gauche -->
    <div class="col-md-3">
      <ng-template #historiqueTpl/>
    </div>
    <!-- Zone de l'image -->
    <div 
      [class]="[atelier.style1, 'zone'].join(' ')"
    >
      <div class="row id-image">Image {{ image?.id}}</div>

      <ng-template #imageTpl/>
      <!-- Zone de saisie -->
      <div class="row">
        <div class="col-md-12 zones-saisies">
          <ng-template #zoneSaisieTpl/>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 message" [innerText]="message">
      </div>
      <div class="zone-technique col-md-12">
        <div 
          class="form-check form-switch  custom-switch"
          [ngClass]="clsBtnPhysique()"
          ngbTooltip="Cocher pour envoyer en atelier physique"
          tabindex="20"
        >
          <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" 
            [(ngModel)]="topPhysique"
            (ngModelChange)="changementTopPhysique()"
          >
          <label class="form-check-label" for="flexSwitchCheckDefault">Physique</label>
        </div>
        <app-bouton [btnClass] = "'btn btn-secondary btn-mini btn-droite'" [iconClass]="'invisible, d-inline'" [libelle]="'Suivante'" 
          (clic)="clicSuivante($event)" 
          ngbTooltip="Passer à l'image suivante (N'enregistre pas la saisie en cours)"
          placement="bottom"
          tabindex="50"
        ></app-bouton>
      </div>
  </div>
</div>
import { Directive, ElementRef, EventEmitter, Host, HostListener, inject, Input, Output } from '@angular/core';
import { Logger } from '../../../models/Logger.model';
import { FonctionsService } from '../../../services/fonctions.service';
import { InputMaskDirectiveService } from './input-mask-directive.service';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[inputMaskDirective]',
  standalone: true
})
export class InputMaskDirective {
  inputMaskDirService = inject(InputMaskDirectiveService, {optional: true});

  @Input() anticipation: number = -1; // Nb de caractère avant chargement img suivante // TODO
  @Input() maxLength: number = 99; // valeur saisie
  @Input() majuscules: number = 0; // 0 = Pas de conversion, 1 = Minuscules, 2 = Majuscules 
  //@Output() yaSaisie: EventEmitter<boolean> = new EventEmitter();
  @Output() imageSuivante: EventEmitter<boolean> = new EventEmitter();
  @Output() valider: EventEmitter<string> = new EventEmitter();
  @Output() echappe: EventEmitter<string> = new EventEmitter();
  @Output() flecheHaut: EventEmitter<string> = new EventEmitter();
  @Output() flecheBas: EventEmitter<string> = new EventEmitter();
  @Output() slash: EventEmitter<string> = new EventEmitter();
  @Output() tabulation: EventEmitter<string> = new EventEmitter();

  logger: Logger = new Logger(this);
  constructor(
    @Host() private ngModel: NgModel,
    private elementRef: ElementRef,
    private fonctionsService: FonctionsService
  ) {
    if(this.inputMaskDirService) {  // Enregistrement de la directive dans le service qui l'expose
      this.inputMaskDirService.setInputMaskDir(this);
    }
  }

  @HostListener('keydown', ['$event']) 
  onkeyDown(event: KeyboardEvent) {
    this.logger.info("onkeyDown", event);
    if(event.key == "Enter") {
      this.traiterEnter(event);
    } else if(event.key == "Escape") {
      this.traiterEchap(event);
    } else if(event.key == "ArrowUp") {
      this.traiterHaut(event);
    } else if(event.key == "ArrowDown") {
      this.traiterBas(event);
    } else if(event.key === 'Tab') {
      this.traiterTab(event);
    } else if(event.code === 'NumpadDivide') {
      this.traiterSlash(event);
    }
  
  }

  @HostListener('keypress', ['$event']) 
  onKeyPress(event: KeyboardEvent) {
    this.logger.info("onKeyPress", event);
    if(event.key == "*" && event.code.startsWith('Numpad') ) {  
      this.ngModel.control.setValue("");
      this.elementRef.nativeElement.value = "";
      event.preventDefault();
      event.stopPropagation();
      this.imageSuivante.emit(false);
    } else {
    }
      
  }

  @HostListener('keyup', ['$event']) 
  onkeyUp(event: KeyboardEvent) {
    this.logger.info("onkeyUp", event);
    if(event.key == "Enter" || event.key == "Escape" || event.key == "/"
      || event.key == "ArrowUp" || event.key == "ArrowDown" || event.key == "Tab"
    ) {
      // on ignore car traité dans le down
      event.preventDefault();
      event.stopPropagation();
    } else {
      if(this.ngModel.model && this.ngModel.model.length == this.maxLength) {
        this.logger.info("traiterEnterAuto", event);
        this.valider.emit(this.ngModel.model);
      }
    }
  }

  /**
   * Traitement de la touche Echap
   * @param event 
   */
  traiterEchap(event: KeyboardEvent) {
    this.logger.info("traiterEchap", event);
    event.preventDefault();
    event.stopPropagation();
    this.echappe.emit("");
  }

  /**
   * Traitement de la touche Entrée
   * @param event 
   */
  traiterEnter(event: KeyboardEvent) {
    this.logger.info("traiterEnter", event);
    this.valider.emit(this.ngModel.model);
  }

  /**
   * Traitement de la touche Flèche haut
   * @param event 
   */
  traiterHaut(event: KeyboardEvent) {
    this.logger.info("traiterHaut", event);
    event.preventDefault();
    event.stopPropagation();
    this.flecheHaut.emit("");
  }

  /**
   * Traitement de la touche Echap
   * @param event 
   */
  traiterBas(event: KeyboardEvent) {
    this.logger.info("traiterBas", event);
    event.preventDefault();
    event.stopPropagation();
    this.flecheBas.emit("");
  }

  /**
   * Traitement de la touche /
   * @param event 
   */
  traiterSlash(event: KeyboardEvent) {
    this.logger.info("traiterSlash", event);
    event.preventDefault();
    event.stopPropagation();
    this.slash.emit("");
  }

  /**
   * Traitement de tabulation
   * @param event 
   */
  traiterTab(event: KeyboardEvent) {
    this.logger.info("traiterTab", event);
    event.preventDefault();
    event.stopPropagation();
    this.tabulation.emit("");
  }

  /**
   * Mise à jour de la valeur de l'input et arrêt event
   * @param event event traité
   * @param curPosNew Nouvelle position sur curseur ou -1 = inchangée
   */
  /*
  mettreAjour(event: KeyboardEvent, curPosNew: number) {
    this.logger.info("mettreAjour", [event, curPosNew]);
    this.elementRef.nativeElement.value = this.valeur;
    if(curPosNew >= 0) {
      this.elementRef.nativeElement.setSelectionRange(curPosNew, curPosNew);
    }
    event.preventDefault();
    event.stopPropagation();
    // export de la saisie
    this.elementRef.nativeElement.setAttribute("data-saisie", this.valeur);
    // Gestion anticipation affichage image
    if(this.anticipation > -1 && this.valeur.length == this.anticipation + 1) {
      this.logger.info("anticipation enclenchée", [this.anticipation, this.valeur.length])
      // Chargement image suivante
      this.imageSuivante.emit(true);
    } else if(this.valeur.length == this.anticipation) {
      // Reaffichage image en cours
      this.imageSuivante.emit(false);
    }
  }
*/
  /**
   * Réinitialisation du input contenant la directive
   */
  razValeur() {
    this.ngModel.control.setValue("");
  }


}

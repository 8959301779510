import { Component, Input, OnInit } from '@angular/core';
import { DlgParams } from '../dlg-params';
import { CommonModule } from '@angular/common';

/**
 * Composant pour afficher du code html dans le corps d'un dialogue
 *   dlgParams.param = Code html à afficher
 */
@Component({
  selector: 'app-dlg-html-corps',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dlg-html-corps.component.html',
  styleUrls: ['./dlg-html-corps.component.scss']
})
export class DlgHtmlCorpsComponent {
  @Input({ required: true }) public dlgParams!: DlgParams;

  constructor() { }

}

import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { DlgParams } from '../dlg-params';
import { WebIndexService } from '../../../services/web-index.service';
import { CommonModule } from '@angular/common';
import { RoleDetailModel } from '../../../models/RoleDetail.model';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dlg-profil-corps',
  standalone: true,
  imports: [CommonModule, NgbTooltip],
  templateUrl: './dlg-profil-corps.component.html',
  styleUrls: ['./dlg-profil-corps.component.scss']
})
export class DlgProfilCorpsComponent implements OnInit, AfterViewInit {
  @Input({ required: true }) public dlgParams!: DlgParams;

  login: string = "";
  nom: string = "";
  prenom: string = "";
  roles: string[] = [];
  erreur: string = "";
  rolesDetail: RoleDetailModel[] = [];

  constructor(
    private webIndexService: WebIndexService,
  ) { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.webIndexService.getUserInfos().subscribe({
      error: (err) => {
        this.erreur = "Une erreur est survenue dans la récupération du profil";
      },
      next: (res: any) => {
        this.login = res.login;
        this.nom = res.nom;
        this.prenom = res.prenom;
        this.roles = res.roles;
        this.rolesDetail = res.rolesDetail;
      },
      complete: () => {},
    });
      
  }

}

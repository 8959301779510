import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MontantDirective } from './montant.directive';

/**
 * Service pour pouvoir accéder à la directive Montant d'un input à partir du parent
 */
@Injectable({
  providedIn: 'root'
})
export class MontantDirectiveService {
  private montantDir$$ = new BehaviorSubject<MontantDirective | undefined>(undefined);
  montantDir$ = this.montantDir$$.asObservable();

  setMontantDir(mtDir: MontantDirective) {
    this.montantDir$$.next(mtDir);
  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { APIKEY, NOM_PROJET, OPERATEUR, SESSION_ID } from '../../../constantes';
import { v4 as uuidv4 } from 'uuid';
import { WebIndexService } from '../../../services/web-index.service';
import { Logger } from '../../../models/Logger.model';
import { oAuthConfig } from '../../../../environments/environment';
import { LoginService } from './login.service';
import { environment } from '../../../../environments/environment';
import { NgClass, NgStyle } from '@angular/common';
import { DialoguesService } from '../../dialogues/dialogues.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-login',
  standalone: true,
  imports: [NgStyle, NgClass],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {
  version: string = "0.0.0";
  apiVersion: string = "0.0.0";
  nomProjet: string = NOM_PROJET;
  appelKeyCloak: boolean = false;

  constructor(
    private loginService: LoginService,
    private webIndexService: WebIndexService,
  ) {}

  ngOnInit(): void {
    this.appelKeyCloak = false;
    this.version = environment.version + " (" + environment.nom + ")";
    this.webIndexService.getApiInfo().subscribe({
      error: (err) => {
        this.apiVersion = "?.?.?"
      },
      next: (res: any) => {
        this.apiVersion = res.apiVersion;
      },
      complete: () => {},      
    })

    this.loginService.subKeycloak$.subscribe({
      error: (err) => {
        // On ne passe jamais
      },
      next: (valeur) => { // Changement de l'état du bouton et curseur
        if(valeur == "ON") {
          this.appelKeyCloak = true;
        } else {
          this.appelKeyCloak = false;
        }
      },
      complete: () => { 
        // rien de plus
      },    
    }); 

  }

  connexion() {
    this.loginService.login();
  }
  
}

<div class="">
  <div id="entete">
    <app-entete 
      [titreComponent]="titreComponent"
      [titreParams]="titreParams"
      [identifie]="true"
      [lstBoutons]="lstBoutons"
      (menuOptionClick)="onMenuOptionClick($event)"
    ></app-entete>
  </div>
  <div id="corps" class="row corps">
    <ng-template #atelierTpl/>
  </div>
</div>
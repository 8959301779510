import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { InputMaskDirective } from './input-mask.directive'

/**
 * Service pour pouvoir accéder à la directive Input d'un input à partir du parent
 */
@Injectable({
  providedIn: 'root'
})
export class InputMaskDirectiveService {
  private inputMaskDir$$ = new BehaviorSubject<InputMaskDirective | undefined>(undefined);
  inputMaskDir$ = this.inputMaskDir$$.asObservable();

  setInputMaskDir(inputMaskDir: InputMaskDirective) {
    this.inputMaskDir$$.next(inputMaskDir);
  }

}

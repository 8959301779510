<div class="">
  <div id="entete">
    <app-entete 
      [titreComponent]="titreComponent"
      [titreParams]="{'titre': 'Identification'}"
      [identifie]="false"></app-entete>
  </div>
  <div id="corps" class="row corps text-center">
    <div class="d-flex justify-content-center align-items-center">
    <div class="spinner-border text-primary" role="status"></div>
  </div>
</div>
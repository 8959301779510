import { Directive, ElementRef, EventEmitter, HostListener, Inject, Injector, Input, OnInit, Output, inject } from '@angular/core';
import { FonctionsService } from '../../../services/fonctions.service';
import { Logger } from '../../../models/Logger.model';
import { MontantDirectiveService } from './montant-directive.service';
import { tap, withLatestFrom } from 'rxjs';
import { PARENT_COMPONENT_TOKEN, StandardSaisieInterface } from './standard-saisie-interface';

/**
 * Directive pour input de saisie de montant en centimes avec affichage en euros
 */
@Directive({
  selector: '[montantDirective]',
  standalone: true,
})
export class MontantDirective {

  montantDirService = inject(MontantDirectiveService, {optional: true});

  @Input() anticipation: number = -1; // Nb de caractère avant chargement img suivante
  @Input() maxLength: number = 12; // Longueur max
  @Output() yaSaisie: EventEmitter<boolean> = new EventEmitter();
  @Output() imageSuivante: EventEmitter<boolean> = new EventEmitter();
  @Output() valider: EventEmitter<string> = new EventEmitter();
  @Output() echappe: EventEmitter<string> = new EventEmitter();
  @Output() slash: EventEmitter<string> = new EventEmitter();

  logger: Logger = new Logger(this);
  constructor(
    private elementRef: ElementRef,
    @Inject(PARENT_COMPONENT_TOKEN) private parent: StandardSaisieInterface,
    private fonctionsService: FonctionsService
  ) {
    if(this.montantDirService) {  // Enregistrement de la directive dans le service qui l'expose
      this.montantDirService.setMontantDir(this);
    }
  }

  @HostListener('keydown', ['$event']) 
  onkeyDown(event: KeyboardEvent) {
    this.logger.info("onkeyDown", event);
    if(event.key == "Backspace") {
      this.traiterBack(event);    
    } else if(event.key == "Delete") {
      this.traiterDelete(event);
    } else if(event.key == "Enter") {
      this.traiterEnter(event);
    } else if(event.key == "Escape") {
      this.traiterEchap(event);
    } else if(event.key == "+") {
      this.traiterZero(event, 3);
    } else if(event.key == ".") {
      this.traiterZero(event, 2);
    } else if(event.key == "/") {
      this.traiterSlash(event);
    } else {
    }
  
  }

  @HostListener('keypress', ['$event']) 
  onKeyPress(event: KeyboardEvent) {
    this.logger.info("onKeyPress", event);
    if(event.key == "*") {
      this.parent.saisie1 = "";
      this.elementRef.nativeElement.value = "";
      event.preventDefault();
      event.stopPropagation();
      this.imageSuivante.emit(false);
    } else {
      if(this.parent.saisie1.length < this.maxLength) {
        if("0123456789".indexOf(event.key) > -1) { 
          let curPosNew: number = 0;
          let curPos: number = (this.elementRef?.nativeElement?.selectionStart) != null ? this.elementRef.nativeElement.selectionStart : 0;
          let valueLength: number = this.elementRef.nativeElement.value.length;
          if(curPos == valueLength) {
            this.parent.saisie1 += event.key;
            curPosNew = this.parent.saisie1.length;
            if(this.parent.saisie1.length > 2) {
              curPosNew ++
            }
          } else if(curPos < valueLength - 2) {
            this.parent.saisie1 = this.parent.saisie1.slice(0, curPos) + event.key + this.parent.saisie1.slice(curPos);
            curPosNew = curPos + 1;
          } else if(curPos >= valueLength - 2) {
            this.parent.saisie1 = this.parent.saisie1.slice(0, curPos - 1) + event.key + this.parent.saisie1.slice(curPos - 1);
            curPosNew = curPos + 1;
          }
          this.mettreAjour(event, curPosNew);
          if(valueLength == 0) {
            this.yaSaisie.emit();
          }
        } else {
          event.preventDefault();
          event.stopPropagation();
        }
      } else {
        event.preventDefault();
        event.stopPropagation();
      }
    }
  }

  @HostListener('keyup', ['$event']) 
  onkeyUp(event: KeyboardEvent) {
    this.logger.info("onkeyUp", event);
    if(event.key == "Backspace" || event.key == "Delete" || event.key == "Enter" || event.key == "Escape" 
        || event.key == "+" || event.key == "."  || event.key == "/") {
      // on ignore car traité dans le down
      event.preventDefault();
      event.stopPropagation();
    }
  }

  /**
   * Traitement de la touche back
   * @param event event à traiter
   */
  traiterBack(event: KeyboardEvent) {
    this.logger.info("traiterBack", event);
    let curPosNew: number = 0;
    let curPos: number = (this.elementRef?.nativeElement?.selectionStart) != null ? this.elementRef.nativeElement.selectionStart : 0;
    let valueLength: number = this.elementRef.nativeElement.value.length;

    if(curPos == 0) { 
      // Début on ne fait rien
    } else if(curPos == valueLength) { // Fin de la valeur
      this.parent.saisie1 = this.parent.saisie1.slice(0, this.parent.saisie1.length - 1);
      if(this.parent.saisie1.length > 2) {
        curPosNew = this.parent.saisie1.length + 1;
      } else {
        curPosNew = this.parent.saisie1.length;
      }
    } else if(this.parent.saisie1.length >= 3 && curPos >= valueLength - 2) {  // Apres la virgule
      this.parent.saisie1 = this.parent.saisie1.slice(0, curPos - 2) + this.parent.saisie1.slice(curPos - 1);
      if(curPos == valueLength - 2) {
        if(this.parent.saisie1.length > 2) {
          curPosNew = this.parent.saisie1.length - 1;
        } else {
          curPosNew = 0;
        }
      } else {
        if(this.parent.saisie1.length > 2) {
          curPosNew = this.parent.saisie1.length;
        } else {
          curPosNew = this.parent.saisie1.length - 1;
        }
      }
    } else if(this.parent.saisie1.length < 3) {  // Pas de virgule
      this.parent.saisie1 = this.parent.saisie1.slice(0, curPos - 1) + this.parent.saisie1.slice(curPos);
      curPosNew = curPos - 1;
    } else {
      this.parent.saisie1 = this.parent.saisie1.slice(0, curPos - 1) + this.parent.saisie1.slice(curPos);
      curPosNew = curPos - 1;
    }
    this.mettreAjour(event,curPosNew);
  }

  /**
   * Traitement de la touche Supp.
   * @param event event à traiter
   */
  traiterDelete(event: KeyboardEvent) {
    this.logger.info("traiterDelete", event);
    let curPosNew: number = 0;
    let curPos: number = (this.elementRef?.nativeElement?.selectionStart) != null ? this.elementRef.nativeElement.selectionStart : 0;
    let valueLength: number = this.elementRef.nativeElement.value.length;
    if(curPos == valueLength) {
      // rien à faire
    } else if(curPos == 0) {  // au début
      this.parent.saisie1 = this.parent.saisie1.slice(curPos + 1);
      curPosNew = curPos;
    } else if(this.parent.saisie1.length > 2) {  // au milieu
      if(curPos < valueLength - 2) { // Avant ou virgule
          this.parent.saisie1 = this.parent.saisie1.slice(0, curPos) + this.parent.saisie1.slice(curPos + 1);
          curPosNew = curPos;
      } else {
        this.parent.saisie1 = this.parent.saisie1.slice(0, curPos-1) + this.parent.saisie1.slice(curPos);
        if(this.parent.saisie1.length == 2) {
          curPosNew = 1;
        } else {
          curPosNew = curPos;
        }
      }
    } else {
      if(curPos == 0) {
        this.parent.saisie1 = this.parent.saisie1.slice(curPos + 1);
        curPosNew = curPos;
      } else {
        this.parent.saisie1 = this.parent.saisie1.slice(0, curPos);
        curPosNew = 1;
      }
    }
    this.mettreAjour(event,curPosNew);
  }

  /**
   * Traitement de la touche Echap
   * @param event 
   */
  traiterEchap(event: KeyboardEvent) {
    this.logger.info("traiterEchap", event);
    event.preventDefault();
    event.stopPropagation();
    this.echappe.emit("");
  }

  /**
   * Traitement de la touche Entrée
   * @param event 
   */
  traiterEnter(event: KeyboardEvent) {
    this.logger.info("traiterEnter", event);
    this.valider.emit(this.parent.saisie1);
  }

  /**
   * Traitement des touches . et + pour ajouter des 0
   * @param event 
   * @param nb 
   */
  traiterZero(event: KeyboardEvent, nb: number) {
    this.logger.info("traiterZero", [event, nb]);
    for(let i = 1; i <= nb; i++) {
      let curPosNew: number = 0;
      let curPos: number = (this.elementRef?.nativeElement?.selectionStart) != null ? this.elementRef.nativeElement.selectionStart : 0;
      let valueLength: number = this.elementRef.nativeElement.value.length;
      if(curPos == valueLength) {
        this.parent.saisie1 += "0";
        curPosNew = this.parent.saisie1.length;
        if(this.parent.saisie1.length > 2) {
          curPosNew += nb
        }
      } else if(curPos < valueLength - 2) {
        this.parent.saisie1 = this.parent.saisie1.slice(0, curPos) + "0" + this.parent.saisie1.slice(curPos);
        curPosNew = curPos + nb;
      } else if(curPos >= valueLength - 2) {
        this.parent.saisie1 = this.parent.saisie1.slice(0, curPos - 1) + "0" + this.parent.saisie1.slice(curPos - 1);
        curPosNew = curPos + nb;
      }
      this.mettreAjour(event, curPosNew);
    }
  }

  /**
   * Traitement de la touche /
   * @param event 
   */
  traiterSlash(event: KeyboardEvent) {
    this.logger.info("traiterSlash", event);
    event.preventDefault();
    event.stopPropagation();
    this.slash.emit("");
  }


  /**
   * Mise à jour de la valeur de l'input et arrêt event
   * @param event event traité
   * @param curPosNew Nouvelle position sur curseur ou -1 = inchangée
   */
  mettreAjour(event: KeyboardEvent, curPosNew: number) {
    this.logger.info("mettreAjour", [event, curPosNew]);
    this.elementRef.nativeElement.value = this.fonctionsService.formatterMontantSaisi(this.parent.saisie1);
    if(curPosNew >= 0) {
      this.elementRef.nativeElement.setSelectionRange(curPosNew, curPosNew);
    }
    event.preventDefault();
    event.stopPropagation();
    // export du montant
    // Gestion anticipation affichage image
    if(this.anticipation > -1 && this.parent.saisie1.length == this.anticipation + 1) {
      this.logger.info("anticipation enclenchée", [this.anticipation, this.parent.saisie1.length])
      // Chargement image suivante
      this.imageSuivante.emit(true);
    } else if(this.parent.saisie1.length == this.anticipation) {
      // Reaffichage image en cours
      this.imageSuivante.emit(false);
    }
  }

  /**
   * Réinitialisation du input contenant la directive
   */
  razMontant() {
    this.parent.saisie1 = "";
    this.elementRef.nativeElement.value = "";
  }

}
<div class="col-md-12 zones-saisies">
  <div class="champ-compte">
    <p class="libelle">No Compte (AG + Num)</p>
    <input type="text" 
      #inputSaisie
      name="saisie1" 
      class="form-control alignement" 
      autofocus
      tabindex="0"
      saisieDirective
      [type]="'9'"
      [maxLength]="12"
      (yaSaisie)="yaSaisie()"
      (imageSuivante)="onImageSuivante($event)"
      (valider)="onTraiterEntree($event)"
      (echappe)="onTraiterEchappe($event)"
    />
  </div>
</div>

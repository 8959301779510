<div class="">
  <div id="entete" class="row">
    <app-entete 
      [titreComponent]="titreComponent"
      [titreParams]="{'titre': 'Choix de l\'atelier'}"
      [identifie]="true"></app-entete>
  </div>
  <div id="corps" class="row corps text-center">
    <table class="table table-hover">
      <thead>
        <tr>
          <th class="md-1">
            <i 
              class="btn icon-docapost icon-refresh-cw btn-primary" 
              [ngbTooltip]="'Rafraîchir la liste'"
              (click)="rafraichir()"
            ></i>
          </th>
          <th scope="col" class="col-md-2" >
            <div class="row">
              <div class="col-sm-10 text-left">
                <app-sel-libelle
                  [idHtml]="'selsite'"
                  [titre] = "'Site'"
                  [liste] = "lstSites"
                  [filtre]="filtreSits"
                  (filtreChange)="onFiltreChange('selsite', $event)"
                ></app-sel-libelle>
              </div>
              <div class="col-sm-1"
                (click)="trier('site')"
              >
                <i class=" icon-reverse"></i>
              </div>
            </div>
          </th>
          <th scope="col" class="col-md-2">
            <div class="row">
              <div class="col-md-10">
                <app-sel-libelle
                  [idHtml]="'seltrt'"
                  [titre] = "'Traitement'"
                  [liste] = "lstTraitements"
                  [filtre]="filtreTrts"
                  (filtreChange)="onFiltreChange('seltrt', $event)"
                ></app-sel-libelle>
              </div>
              <div class="col-md-2 text-right"
                (click)="trier('traitement')"
              >
                <i class=" icon-reverse"></i>
              </div>
            </div>
          </th>
          <th scope="col">
            <div class="row">
              <div class="col-md-10">
                <app-sel-libelle
                  [idHtml]="'selatl'"
                  [titre] = "'Atelier'"
                  [liste] = "lstAteliers"
                  [filtre]="filtreAtls"
                  (filtreChange)="onFiltreChange('selatl', $event)"
                ></app-sel-libelle>
              </div>
              <div class="col-md-2 text-right"
                (click)="trier('atelier')"
              >
                <i class=" icon-reverse"></i>
              </div>
            </div>
          </th>
          <th scope="col" class="col-md-2">
            <div class="row">
              <div class="col-md-10">
                Date Traitement
              </div>
              <div class="col-md-2 text-right"
                (click)="trier('date')"
              >
                <i class=" icon-reverse"></i>
              </div>
            </div>
          </th>
          <th scope="col" class="col-md-2" >
            <div class="row">
              <div class="col-md-8">
                Volume à saisir
              </div>
              <div class="col-md-2 text-right"
                (click)="trier('volume')"
              >
                <i class=" icon-reverse"></i>
              </div>
            </div>
          </th>
          <th scope="col" class="col-md-2" >
            <div class="row">
              <div class="col-md-10">
                Age
              </div>
              <div class="col-md-2 text-right"
                (click)="trier('age')"
              >
                <i class=" icon-reverse"></i>
              </div>
            </div>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let atlStock of atelierStock"
          class="cliquable"
          (click)="onStockClick(atlStock)"
        >
          <td></td>
          <td>{{atlStock.site.libelle}}</td>
          <td>{{atlStock.traitement.libelle}}</td>
          <td>{{atlStock.atelier.libelle}}</td>
          <td >{{ fonctionsService.afficherDate(atlStock.dateTraitement)}}</td>
          <td>
            <app-stock-pill [nbASaisir]="atlStock.nbASaisir" [stockMax]="stockMax"></app-stock-pill>
          </td>
          <td>
            <app-age [date]="atlStock.dateIntMin"></app-age>
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>
    </div>
</div>
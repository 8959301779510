import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DlgParams } from '../dlg-params';
import { CommonModule } from '@angular/common';

/**
 * Composant pour afficher un titre standard dans un dialogue
 * Personnalisation par
 *   dlgParams.titreIconClass = icone à afficher (classe css)
 *   dlgParams.titre = Titre à afficher
 */
@Component({
  selector: 'app-dlg-titre',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dlg-titre.component.html',
  styleUrls: ['./dlg-titre.component.scss']
})
export class DlgTitreComponent {
  @Input({required: true}) public dlgParams!: DlgParams;

  constructor() { }

}

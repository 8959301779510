import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FonctionsService } from '../../../services/fonctions.service';
import { StockPillComponent } from '../../stock-pill/stock-pill.component';
/**
 * Composant titre pour atelier de saisie
 */
@Component({
  selector: 'app-titre-atelier',
  standalone: true,
  imports: [StockPillComponent],
  templateUrl: './titre-atelier.component.html',
  styleUrl: './titre-atelier.component.scss'
})
export class TitreAtelierComponent implements OnInit {
  /** SiteId de l'atelier sélectionné */
  @Input() siteId: string = "";
  /** traitementId sélectionné */
  @Input() traitementId: string = "";
  /** atelierId selectionné */
  @Input() atelierId: string = "";
  /** libellé de l'atelier */
  @Input() atelierLib: string = "";
  /** date de traitement selectionnée */
  @Input() dateTraitement: string = "";
  /** Stock max d'image pour calcul couleur */
  @Input() nbASaisirMax: number = 1;
  /** Stock d'image restant à saisir */
  @Input() nbASaisir: number = -1;
  
  titre: string = "";
  
  constructor(
    private fonctionsService: FonctionsService,
  ) {}

  ngOnInit(): void {
    this.setTitrePage();            
  }
  
  /**
   * Calcul du titre
   */
  setTitrePage() {
    this.titre = this.siteId + ' - ' + this.traitementId + ' - ' + this.atelierLib 
                      + " du " + this.fonctionsService.afficherDate(this.dateTraitement);
  }

}

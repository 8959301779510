import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ApiImage } from '../models/ApiImage.model';
import { DynamiqueService } from './dynamique.service';
import { DialoguesService } from '../components/dialogues/dialogues.service';
import { NOM_PROJET } from '../constantes';

@Injectable({
  providedIn: 'root'
})
export class FonctionsService {

  constructor(
    private datePipe: DatePipe,
    private dynamiqueService: DynamiqueService,
  ) { }

  public urlBuild(...urlPart: string[]):string {
    let url:string = "";
    if(urlPart.length === 0 || urlPart == undefined || urlPart === null ) {
      url = "";
    } else {
      url = urlPart[0];
      if(url.endsWith("/")) {
        url = url.slice(0, -1);
      }
      for(let i = 1; i < urlPart.length; i++) {
        url += "/" + urlPart[i].replace("/", "");
      }
    }
    return url;
  }

  public maintenant(): string {
    const sDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss.SSS');
    return sDate != null ? sDate : "";
  }

  public formatterMontantSaisi(montant: string) {
    if(montant == null) {
      return "0";
    }
    if(montant.length > 2 ) {
      return montant.slice(0, -2).concat(",", montant.slice(-2));
    } else {
      return montant
    }
  }

  afficherDate(date: string) { // yyyyMMdd
    return date.substring(6) + "/" + date.substring(4, 6) + "/" + date.substring(0, 4);
  }

  afficherDateHeure(date: string) { // yyyyMMdd HHmmss
    return date.substring(6, 8) + "/" + date.substring(4, 6) + "/" + date.substring(0, 4)
            + " " + date.substring(9, 11) + ":" + date.substring(11, 13) + ":" + date.substring(13, 15)
    ;
  }

  yyyyMMddHHmmss2Date(chaine: string): Date {
    const annee = parseInt(chaine.substring(0, 4), 10);
    const mois = parseInt(chaine.substring(4, 6), 10) - 1;
    const jour = parseInt(chaine.substring(6, 8), 10);
    const heure = parseInt(chaine.substring(9, 11), 10);
    const minute = parseInt(chaine.substring(11, 13), 10);
    const seconde = parseInt(chaine.substring(13, 15), 10);
  
    return new Date(annee, mois, jour, heure, minute, seconde);
  }
  
  ddMMaaEstDate(chaine: string): boolean {
    const annee = parseInt(chaine.substring(4, 6), 10) + 2000;
    const mois = parseInt(chaine.substring(2, 4), 10) - 1;
    const jour = parseInt(chaine.substring(0, 2), 10);
  
    const date = new Date(annee, mois, jour);
    return date.getFullYear() === annee && date.getMonth() === mois && date.getDate() === jour;
  }

  hhmmEstHeure(chaine: string): boolean {
    const heures = parseInt(chaine.substring(0, 2), 10);
    const minutes = parseInt(chaine.substring(2, 4), 10);
    if (heures < 0 || heures > 23 || minutes < 0 || minutes > 59) {
      return false;
    } else {
      return true;
    } 
  }

  calculerNbHeures(date: Date): number {
    const maintenant = new Date();
    const diff = maintenant.getTime() - date.getTime();
    return diff / (1000 * 60 * 60);
  }

  estNumerique(str: string): boolean {
    // Expression régulière pour vérifier que la chaîne ne contient que des chiffres
    const regex = /^[0-9]+$/;
    return regex.test(str);
  }

  /**
   * Teste si une chaine est alphabétique sans accents
   * @param chaine 
   * @returns 
   */
  estAlphabetique(chaine: string) {
    const regex = /^[a-zA-Z]+$/;
    return regex.test(chaine);
  }

  estAlphabetiqueMajuscule(chaine: string) {
    const regex = /^[A-Z]+$/;
    return regex.test(chaine);
  }

  estAlphabetiqueMinuscule(chaine: string) {
    const regex = /^[a-z]+$/;
    return regex.test(chaine);
  }

  apiImage2Form(image: ApiImage, modeSaisie: string): any {
    const { image64, ...proprietes} = image;
    const frmImage: {[key: string]: any} = {};
    Object.assign(frmImage, proprietes);
    frmImage["modeSaisie"] = modeSaisie;
    return frmImage;
  }

  /**
   * Décodage d'un paramère de fonction d'un atelier
   * @param param Paramètre sous la forme Service.fonction
   * @param defautFn Nom de la fonction par défaut dans StandardSaisieService
   * @returns Le service et le nom de la fonction
   */
  litParametreDeService(param: string | null, defautFn: string): { service: any, fonction: string } {
    let service: any;
    let fonction: string;
    if(param == null || param == "") {
      service = this.dynamiqueService.chargerService("");
      fonction = defautFn;
    } else {
      const [serviceName, methodName] = param.split('.');
      service = this.dynamiqueService.chargerService(serviceName);
      fonction = methodName;
    }
    return { service, fonction };
  }

  /**
   * Transforme une chaine cle1=val1;cle2=val2 en map
   * @param str 
   * @returns 
   */
  str2Map(str: string): Map<string, string> {
    const map = new Map<string, string>();
    if(str != null) {
      const parse = str.split(";");

      parse.forEach(cleVal => {
        const [cle, valeur] = cleVal.split("=");
        if(cle && valeur) {
          map.set(cle, valeur);
        }
      })
    }
    return map;
  }

  /**
   * Convertis une map en chaine clé=valeur;
   * @param map 
   * @returns 
   */
  map2Str(map: Map<string, string>): string {
    let str = "";
    for(const [cle, valeur] of map.entries()) {
      str += cle + "=" + valeur + ";";
    }
    return str;
  }

  /**
   * Ajoute des caractères au début d'une chine
   * @param sChaine Chaine originale
   * @param iLong Longueur de la chaine à atteindre
   * @param sCar Caractère à ajouter
   * @returns Chaine complétée
   */
  padL(sChaine: string | number | null, iLong: number, sCar: string): string {
    if (sChaine === null) {
        return sCar.repeat(iLong);
    } else {
      if(typeof sChaine === "number") {
        sChaine = sChaine.toString();
      }
      return (sCar.repeat(iLong) + sChaine).slice(-iLong);
    }
  }

  /**
   * Ajoute des caractères à la fin d'une chaine
   * @param sChaine Chaine originale
   * @param iLong Longueur de la chaine à atteindre
   * @param sCar Caractère à ajouter
   * @returns Chaine complétée
   */
  padR(sChaine: string | number | null, iLong: number, sCar: string): string {
    if (sChaine === null) {
        return sCar.repeat(iLong);
    } else {
      if(typeof sChaine === "number") {
        sChaine = sChaine.toString();
      }
      return (sChaine + sCar.repeat(iLong)).slice(0, iLong);
    }
  }

  /**
   * Récupère la partie gauche d'une chaine
   * @param sChaine Chaine originale
   * @param nbCar Nombre de caractères à récupérer
   * @returns Chaine tronquée
   */
  left(sChaine: string, nbCar: number): string {
    if(sChaine.length > nbCar) {
      return sChaine.slice(0, nbCar);
    } else {
      return sChaine;
    }
  }

  /**
   * Récupère la partie droite d'une chiane
   * @param sChaine Chaine originale
   * @param nbCar Nombre de caractères à récupérer
   * @returns Chaine tronquée
   */
  right(sChaine: string, nbCar: number): string {
    if(sChaine.length > nbCar) {
      return sChaine.slice(- nbCar);
    } else {
      return sChaine;
    }
  }

  /**
   * Calcul d'une clé rlmc
   * @param sChaine Ligne cmc7
   * @returns 
   */
  cleRLMC(sChaine: string): string {
    let lSomme = 0;
    let sTemp = sChaine + "0";

    while (sTemp.length > 0) {
        lSomme = (parseInt(sTemp.charAt(0) + "0") + (lSomme * 10)) % 97;
        sTemp = sTemp.substring(1);
    }
    return this.padL(97 - lSomme, 2, "0");
  }

  /**
   * Fonction de calcul de modulo
   * @param sValeur 
   * @param iModulo 
   * @returns 
   */
  modulo(sValeur: string | number, iModulo: number): string {
    let iCpt: number;
    let iCle: number = 0;
    let sCleMod: string;
    const vEquival: string = "0123456789       12345678912345678923456789";
    if(typeof sValeur === 'number') {
      sValeur = sValeur.toString();
    }
    try {
      sCleMod = sValeur.trim();

      for (iCpt = 0; iCpt < sCleMod.length; iCpt++) {
        iCle = iCle * 10 + parseInt(vEquival.charAt(sCleMod.charCodeAt(iCpt) - 47), 10);
        if (iCle >= iModulo) {
            iCle = iCle % iModulo;
        }
      }
      return this.padL(iCle.toString(), 2, "0");
    } catch (error) {
        return "-1";
    }
}

  /**
   * Teste l'existence d'une fonction paramétrée dans composant atelier
   * L'appel doit être lancé avec setTimeout si lancé dans onInit
   * @param atlComponent Composant atelier
   * @param service Service paramétré
   * @param fonction Fonction paramétrée
   */
  testServiceFonctionAtl(service: any, fonction: string, callbackKo: Function) {
    if( !(fonction in service)) {
      callbackKo(service.constructor.name + "." + fonction + " n'existe pas");
    }
  }

  /**
   * Teste l'existence d'une fonction paramétrée dans un composant saisie
   * L'appel doit être lancé avec setTimeout si lancé dans onInit
   * @param saisieComponent Composant de saisie possedant onError
   * @param service Service paramétré
   * @param fonction Fonction paramétrée
   */
  testServiceFonction(service: any, fonction: string, callbackKo: Function) {
    if( !(fonction in service)) {
      callbackKo(service.constructor.name + "." + fonction + " n'existe pas");
      //saisieComponent.onError.emit(service.constructor.name + "." + fonction + " n'existe pas");
    }
  }


  
}

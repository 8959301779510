import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { APIKEY } from "./constantes";

export namespace AppGuard {
  export const canActivate = (
      route: ActivatedRouteSnapshot, 
      state: RouterStateSnapshot
  ) => {
      const router = inject(Router);

      if((sessionStorage.getItem(APIKEY) ?? "").length > 0) {
        return true;
      } else {
        router.navigate(["login"]);
        return false;
      }
  }

  export const canActivateChild = (
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
  ) => canActivate(route, state);
}
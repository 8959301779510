import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-bouton',
  standalone: true,
  templateUrl: './bouton.component.html',
  styleUrls: ['./bouton.component.scss']
})
export class BoutonComponent implements OnInit {
  @Input({required: true}) btnClass: string = "";
  @Input({required: true}) iconClass: string = "";
  @Input({required: true}) libelle: string = "Mon Bouton";
  @Output() clic = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onClick() {
    this.clic.emit();
  }

}

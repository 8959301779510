<div *ngIf="erreur.length > 0" 
  class="card-ope profil-div"
>
  {{ erreur }}
</div>
<div *ngIf="erreur.length == 0" 
  class="card-ope profil-div"
>
  <div class="row">
    <div class="col-md-6 ligneInfo">
      <h6 class="ligneLibelle">Login</h6>
      <div class="ligneValeur">
        {{ login}}
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 ligneInfo">
      <h6 class="ligneLibelle">Nom </h6>
      <div class="ligneValeur">
        {{ nom }}
      </div>
    </div>
    <div class="col-md-6 ligneInfo">
      <h6 class="ligneLibelle">Prenom </h6> 
      <div class="ligneValeur">
        {{ prenom }}
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 ligneInfo">
      <h6 class="ligneLibelle separateur">Roles Saisies</h6>
      <div class="role-scrollable">
        <div class="row role-lib">
          <span class="col-md-1">Site</span>
          <span class="col-md-4">Traitement</span>
          <span class="col-md-3">Atelier</span>
          <span class="col-md-4 droite" >Anticipation</span>
      </div>
      <span class="rolDet" *ngFor="let rolDet of rolesDetail">
          <div class="row role">
              <span class="col-md-1" [ngbTooltip]="rolDet.siteLib">{{ rolDet.siteId }}</span>
              <span class="col-md-4" [ngbTooltip]="rolDet.traitementId">{{ rolDet.traitementLib }}</span>
              <span class="col-md-6" [ngbTooltip]="rolDet.atelierId">{{ rolDet.atelierLib }}</span>
              <span class="col-md-1 droite" >{{ rolDet.anticipation }}</span>
          </div>
        </span>
      </div>
    </div>
  </div>
</div>

import { AfterViewInit, Component, Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { EnteteComponent } from '../../entetes/entete/entete.component';
import { AtelierStockList } from '../../../models/AtelierStockList.model';
import { ChoixSaisiePageService } from './choix-saisie-page.service';
import { Logger } from '../../../models/Logger.model';
import { NgFor, NgForOf, NgIf } from '@angular/common';
import { NavigationExtras, Router } from '@angular/router';
import { AtelierStock } from '../../../models/AtelierStock.model';
import { FonctionsService } from '../../../services/fonctions.service';
import { NgbNav, NgbDropdown, NgbDropdownItem, NgbDropdownToggle, NgbDropdownMenu, NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { SelLibelleComponent } from "./sel-libelle/sel-libelle.component";
import { IdLibelle } from '../../../models/IdLibelle.model';
import { DialoguesService } from '../../dialogues/dialogues.service';
import { ErreursService } from '../../../services/erreurs.service';
import 'bootstrap';
import { AgeComponent } from './age/age.component';
import { StockPillComponent } from '../../stock-pill/stock-pill.component';
import { TitreStdComponent } from '../../entetes/titre-std/titre-std.component';

/**
 * Page de choix du travail
 */
@Component({
    selector: 'app-page-choix-saisie',
    standalone: true,
    templateUrl: './page-choix-saisie.component.html',
    styleUrl: './page-choix-saisie.component.scss',
    providers: [
        NgbDropdown
    ],
    imports: [
        EnteteComponent,
        NgFor, NgForOf, NgIf,
        NgbDropdown,
        NgbDropdownItem,
        NgbDropdownToggle,
        NgbDropdownMenu,
        NgbTooltip,
        SelLibelleComponent,
        AgeComponent,
        StockPillComponent,
        TitreStdComponent
    ]
})

export class PageChoixSaisieComponent implements AfterViewInit {
  logger: Logger = new Logger(this);
  lstAtlStock: AtelierStockList = {} as AtelierStockList; // Liste originale sans filtre
  atelierStock: AtelierStock[] = []; // Liste filtré pour affichage
  lstSites: IdLibelle[] = [];
  lstTraitements: IdLibelle[] = [];
  lstAteliers: IdLibelle[] = [];      // on ne garde que 2 infos
  filtreSits: Set<string> = new Set();
  filtreTrts: Set<string> = new Set();
  filtreAtls: Set<string> = new Set();
  triColonne: string = "";
  stockMax: number = 1;
  triOrdre: number = 1;
  titreComponent = TitreStdComponent;
  
  constructor(
    private router: Router,
    private choixSaisiePageService: ChoixSaisiePageService,
    private erreurService: ErreursService,
    public fonctionsService: FonctionsService,
  ) {}

  ngAfterViewInit(): void {
    this.choixSaisiePageService.getListeAtelierDates().subscribe({
      error: (err) => {
        this.logger.debug("getListeAtelierDates err", err);
        this.erreurService.erreurApi("Choix atelier", err, 
          this.router, () => { 
            sessionStorage.clear; 
            this.router.navigate(["login"]); 
          }
        )
      },
      next: (res: any) => {
        this.logger.debug("getListeAtelierDates res", res);
        this.lstAtlStock = res;
        // Récupérer valeur stock max
        this.stockMax = this.lstAtlStock.atelierStock.reduce( 
          (max, stock) => {
            return (stock.nbASaisir > max) ? stock.nbASaisir : max;
          },
          this.lstAtlStock.atelierStock[0].nbASaisir
        )
        // Extraction des sites
        this.lstSites = this.lstAtlStock.atelierStock.map(s => s.site)
            .filter((value, index, self) => self.findIndex( s => s.id === value.id) === index);
        // extraction des traitements
        this.lstTraitements = [...new Set(this.lstAtlStock.atelierStock.map(s => s.traitement))]
            .filter((value, index, self) => self.findIndex( t => t.id === value.id) === index);
        // extraction des ateliers
        this.lstAteliers = [...new Set(this.lstAtlStock.atelierStock.map(
          a => ({ id: a.atelier.id, libelle: a.atelier.libelle })))
        ].filter((value, index, self) => self.findIndex( t => t.id === value.id) === index);

        this.lstSites.forEach(
          (el: IdLibelle) => {
            this.filtreSits.add(el.id)    
          }
        );
        this.lstTraitements.forEach(
          (el: IdLibelle) => {
            this.filtreTrts.add(el.id)    
          }
        );
        this.lstAteliers.forEach(
          (el: IdLibelle) => {
            this.filtreAtls.add(el.id)    
          }
        );
        this.filtrer();
      },
      complete: () => {},

    })
  }

  trier(colonne: string) {
    let valeur1 = 1;
    switch(colonne) {
      case "site":
        if(this.triColonne == colonne) {
          valeur1 = -this.triOrdre
        } else {
          valeur1 = 1;
        }
        this.atelierStock.sort((a, b) => (a.site.libelle > b.site.libelle) ? valeur1 : -valeur1);
        break;
      case "traitement":
          if(this.triColonne == colonne) {
            valeur1 = -this.triOrdre
          } else {
            valeur1 = 1;
          }
          this.atelierStock.sort((a, b) => (a.traitement.libelle > b.traitement.libelle) ? valeur1 : -valeur1);
          break;
      case "atelier":
        if(this.triColonne == colonne) {
          valeur1 = -this.triOrdre
        } else {
          valeur1 = 1;
        }
        this.atelierStock.sort((a, b) => (a.atelier.libelle > b.atelier.libelle) ? valeur1 : -valeur1);
        break;
      case "date":
        if(this.triColonne == colonne) {
          valeur1 = -this.triOrdre
        } else {
          valeur1 = 1;
        }
        this.atelierStock.sort((a, b) => (a.dateTraitement > b.dateTraitement) ? valeur1 : -valeur1);
        break;
      case "volume":
        if(this.triColonne == colonne) {
          valeur1 = -this.triOrdre
        } else {
          valeur1 = 1;
        }
        this.atelierStock.sort((a, b) => (a.nbASaisir > b.nbASaisir) ? valeur1 : -valeur1);
        break;
    }
    this.triColonne = colonne;
    this.triOrdre = valeur1;
  }

  calculerBadgeClass(stock: number) {
    const pc = Math.round(stock * 100 / this.stockMax);
    if(pc > 75) {
      return "badge badge-stock badge-danger badge-pill"
    } else if(pc > 50) {
      return "badge badge-stock badge-warning badge-pill"
    } else {
      return "badge badge-stock badge-primary badge-pill"
    }

  }

  onFiltreChange(idHtml: string, filtre:Set<string>) {
    this.logger.info("onFiltreChange", [idHtml, filtre]);
    if(idHtml == "selsite") {
      // filtre sur site
      this.filtreSits = filtre;
    } else if(idHtml == "seltrt") {
      // filtre sur traitement
      this.filtreTrts = filtre;
    }
    this.filtrer()
  }

  filtrer() {
    this.atelierStock = this.lstAtlStock.atelierStock.filter( (el) => {
      return this.filtreSits.has(el.site.id)
    }).filter( (el) => {
      return this.filtreTrts.has(el.traitement.id)
    }).filter( (el) => {
      return this.filtreAtls.has(el.atelier.id)
    })
  }

  rafraichir() {
    location.reload();
  }

  onStockClick(stock: AtelierStock)  {
    this.logger.info("onDateClick", stock);
    const cpm = stock.atelier.composant.split("#");   // Composant précédé de la route
    this.router.navigate([`/${cpm[0]}`], {
      state: {
        siteId: stock.site.id,
        traitementId: stock.traitement.id,
        atelier: stock.atelier,
        dateTraitement: stock.dateTraitement
    }});
  }

}

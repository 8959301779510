import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { DlgParams } from '../dlg-params';

@Component({
  selector: 'app-dlg-deux-boutons',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dlg-deux-boutons.component.html',
  styleUrl: './dlg-deux-boutons.component.scss'
})
export class DlgDeuxBoutonsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() dlgParams!: DlgParams;

  @Output() btnEventEmit = new EventEmitter<string>();

  @ViewChild("btn1") btn1!: ElementRef;
  @ViewChild("btn2") btn2!: ElementRef;

  dlgSubscription!: Subscription; // pour touche raccourcis dialogue

  ngOnInit(): void {
    this.dlgSubscription = fromEvent(document, "keyup").subscribe( 
      event => this.gererTouches(event)
    );
  }

  ngAfterViewInit(): void {
    if(this.dlgParams.getParam("timeout") > 0)  {
      setTimeout( () => { this.btnClick(this.dlgParams.getParam("defaut")) }, this.dlgParams.getParam("timeout"));
    }    
  }

  ngOnDestroy(): void {
    this.dlgSubscription.unsubscribe();    
  }

  btnClick(numero: number)  {
    this.btnEventEmit.emit('action' + numero);
  }

  gererTouches(event: any) {
    if (event.key === 'Escape') {
      this.btnEventEmit.emit('close');
    } else if (event.key === 'Enter') {
      this.entree();
    } else if(event.key == "ArrowLeft" || event.key == "ArrowRight") {
      this.basculeFocus();
    } else if (event.key.toLowerCase() === this.dlgParams.getParam("btnLib1").slice(0, 1)) {
      this.btnClick(1);
    } else if (event.key.toLowerCase() === this.dlgParams.getParam("btnLib2").slice(0, 1)) {
      this.btnClick(2);
    }1
  }

  /**
   * Action sur Entree
   */
  entree() {
    let element = document.activeElement as HTMLElement;
    element.click();
  }
  
  /**
   * Action pour touches fléchées
   */
  basculeFocus() {
    if(this.btn1.nativeElement.id == document.activeElement!.id) {
      this.btn2.nativeElement.focus();
    } else {
      this.btn1.nativeElement.focus();
    }
  }

}

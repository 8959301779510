/**
 * Object pour passage de paramètres dans DlgGenericModal et ses composants fils
 */
export class DlgParams {
    public titre: string = "";           // Texte du titre pour composants communs
    public titreIconClass: string = "";  // Classe css pour l'icone de titre pour composants communs
    public titreClass: string = "";      // Class du texte du titre (Erreurs) pour composants communs

    private params: Map<string, object> = new Map();      // Map de paramètres multi usage selon dialogue
    public closeCallback!: Function;    // Fonction callback lors de la fermeture pour routage par exemple
    public callBackFermerAvant: boolean = true; // Le dialogue est fermé avant l'appel

    public setTitre(titre: string): DlgParams {
        this.titre = titre;
        return this;
    }

    public setTitreClass(titreClass: string): DlgParams {
        this.titreClass = this.titreClass;
        return this;
    }

    public setTitreIconClass(iconClass: string): DlgParams {
        this.titreIconClass = iconClass;
        return this;
    }

    public getParam(nomParam: string): any {
        return this.params.get(nomParam);
    }

    public setParam(nomParam: string, valeur: any): DlgParams {
        this.params.set(nomParam, valeur);
        return this;
    }

    public setCloseCallback(closeCallback: any): DlgParams {
        this.closeCallback = closeCallback;
        return this;
    }


}

import { Component, ElementRef, EventEmitter, HostListener, Injector, Input, OnChanges, OnInit, Output, Pipe, PipeTransform, QueryList, SimpleChange, SimpleChanges, ViewChild, ViewChildren, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { WebIndexService } from '../../../../../services/web-index.service';
import { Atelier } from '../../../../../models/Atelier.model';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { tap, withLatestFrom } from 'rxjs';
import { FonctionsService } from '../../../../../services/fonctions.service';
import { DynamiqueService } from '../../../../../services/dynamique.service';
import { PARENT_COMPONENT_TOKEN, StandardSaisieInterface } from '../../../standard/standard-saisie-interface';
import { MontantDirective } from '../../../standard/montant.directive';
import { MontantDirectiveService } from '../../../standard/montant-directive.service';
import { BoutonComponent } from '../../../../bouton/bouton.component';

@Component({
  selector: 'app-lbp-saisie-montant',
  standalone: true,
  templateUrl: './lbp-saisie-montant.component.html',
  styleUrl: './lbp-saisie-montant.component.scss',
  providers: [
    provideNgxMask(),
    WebIndexService,
    { provide: PARENT_COMPONENT_TOKEN, useExisting: LbpSaisieMontantComponent }
  ],
  imports: [    
    NgxMaskDirective,
    FormsModule,
    NgbTooltip,
    MontantDirective,
    BoutonComponent
  ]
})

export class LbpSaisieMontantComponent implements OnInit, StandardSaisieInterface {
  montantDirService = inject(MontantDirectiveService);

  @Input({required: true}) siteId: string = "";
  @Input({required: true}) traitementId: string = "";
  @Input({required: true}) atelier: Atelier = {} as Atelier;
  @Input() clsBouton: string = "btn btn-secondary btn-fond-neutre";
  @Output() onImageChange: EventEmitter<boolean> = new EventEmitter();
  @Output() onValidation: EventEmitter<boolean> = new EventEmitter();
  @Output() onMessage: EventEmitter<string> = new EventEmitter();
  @Output() onEchappe: EventEmitter<string> = new EventEmitter();
  @Output() onSlash: EventEmitter<string> = new EventEmitter();
  @Output() onError: EventEmitter<any> = new EventEmitter();

  @ViewChild('montantDirective') montantDirective!: MontantDirective;
  @ViewChild('inputSaisie', { static: true }) inputSaisie: ElementRef<HTMLInputElement>= {} as ElementRef<HTMLInputElement>;
  
  saisie1: string = "";
  saisie2: string = "";
  controleService: any = null;
  controleFn: string = "";
  montantPrec: string = "";

  constructor(
    private injector: Injector,
    private dynamiqueServices: DynamiqueService,
    private fonctionsService: FonctionsService,
  ) {
   }

   ngOnInit(): void {
    // Préchargement du service
    const [serviceName, methodName] = this.atelier.fonctionCtrl.split('.');
    this.controleService = this.dynamiqueServices.chargerService(serviceName);
    this.controleFn = methodName;       
   }
   
  /**
   * Traitement modification d'image affiché
   * @param bSuivante True pour afficher l'image suivante, false pour afficher l'image courante
   */
  onImageSuivante(bSuivante: boolean) {
    this.onImageChange.emit(bSuivante);
  }

  /**
   * Traitement touche Esc
   * @param event (pas utilisé)
   */
  onTraiterEchappe(event: string) {
    this.onEchappe.emit(event);
  }

  /**
   * Traitement après appui sur Entrée
   * @param montant Montant saisie en centimes
   */
  onTraiterEntree(montant: string) {
    if(this.controleFn.length > 0) {
      const msg: string = this.controleService[this.controleFn](this, montant);
      if(msg.length == 0) {
        this.onValidation.emit(true);
      } else {
        this.onImageChange.emit(false); // On réaffiche l'image
        this.onMessage.emit(msg);
      }  
    }

  }

  /**
   * Traitement après appui sur / pour revenir au document précédent
   */
  onTraiterSlash(event: any) {
    this.onSlash.emit("");
  }

  /**
   * Action quand un caractère est saisi
   */
  yaSaisie() {
    // On efface le message d'erreur
    this.onMessage.emit("");
  }

  focus() {
    this.inputSaisie.nativeElement.focus();
  }

  razSaisie() {
    this.montantDirService?.montantDir$.pipe(
      withLatestFrom(this.montantDirService?.montantDir$),
      tap(([_, mtDir]) => {
        mtDir?.razMontant();
      })
    ).subscribe();    
  }

  setSaisie1(montant: string) {
    this.inputSaisie.nativeElement.value = this.fonctionsService.formatterMontantSaisi(montant); 
    this.saisie1 = montant; 
  }

  setSaisie2(valeur: string | null) {
    if(valeur == null) {
      this.saisie2 = "          ";
    } else {
      this.saisie2 = valeur;
    }
    this.calClsFraude();
  }

  /**
   * Action clic sur le bouton Fraude
   * @param any 
   */
  onFraudeClic(any: Event) {
    if(this.saisie2.substring(0, 1) == "1") {
      this.saisie2 = "0" + this.saisie2.substring(1);
    } else {
      this.saisie2 = "1" + this.saisie2.substring(1);
    }
    this.calClsFraude();
    this.inputSaisie.nativeElement.focus();
  }

  /**
   * Calcule les classes du bouton fraude
   */
  calClsFraude() {
    if(this.saisie2.substring(0, 1) == "1") {
      //this.saisie2 = "0" + this.saisie2.substring(1);
      this.clsBouton = "btn btn-secondary btn-fond-select";
    } else {
      //this.saisie2 = "1" + this.saisie2.substring(1);
      this.clsBouton = "btn btn-secondary btn-fond-neutre";
    }
  }

    /**
   * Fonction appelé lors de l'insertion dans l'historique
   * @returns Valeur saisie affiché dans l'historique
   */
    historique(): string {
      return this.saisie1;
    }
  
}


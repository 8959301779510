/**
 * Fichier à importer dans tous les environnements 
 * pour une gestion centralisée de la version
 */
export const version = {
  version: '1.2.1'  // 08/11/2024 HYE : Deboguage centre
  
  /*
  version: '1.2.0'  // 08/11/2024 HYE : Atelier Lbp Zone2
  version: '1.1.5'  // 31/10/2024 HYE : Correction bogues paramétrage fonctions, + contrôles
  version: '1.1.4'  // 30/10/2024 HYE : Correction bogue atl cmc7, nouvelle gestion version
  */
};
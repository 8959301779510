import { InjectionToken } from "@angular/core";

/**
 * Interface pour atelier de saisie utilisant le champ saisie1, à lequel se réfère les directives et méthodes standards
 * quand la valeur du input est transformé pour affichage
 */
export interface StandardSaisieInterface {
  saisie1: string;
}

export const PARENT_COMPONENT_TOKEN = new InjectionToken<StandardSaisieInterface>('StandardSaisieInterface');
import { Component, ElementRef, EventEmitter, inject, Injector, Input, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { WebIndexService } from '../../../../../services/web-index.service';
import { Atelier } from '../../../../../models/Atelier.model';
import { DynamiqueService } from '../../../../../services/dynamique.service';
import { FonctionsService } from '../../../../../services/fonctions.service';
import { tap, withLatestFrom } from 'rxjs';
import { SaisieDirective } from '../../../../saisieDirective/saisie.directive';
import { SaisieDirectiveService } from '../../../../saisieDirective/saisie-directive.service';

@Component({
  selector: 'app-bnpp-saisie-compte',
  standalone: true,
  providers: [
    provideNgxMask(),
    WebIndexService,
  ],
  imports: [
    NgxMaskDirective,
    FormsModule,
    NgbTooltip,
    SaisieDirective
  ],
  templateUrl: './bnpp-saisie-compte.component.html',
  styleUrl: './bnpp-saisie-compte.component.scss'
})
export class BnppSaisieCompteComponent {
  saisieDirService = inject(SaisieDirectiveService);

  @Input({required: true}) siteId: string = "";
  @Input({required: true}) traitementId: string = "";
  @Input({required: true}) atelier: Atelier = {} as Atelier;
  @Input({required: true}) anticipation: number = 0;

  @Output() onImageChange: EventEmitter<boolean> = new EventEmitter();
  @Output() onValidation: EventEmitter<any> = new EventEmitter();
  @Output() onMessage: EventEmitter<string> = new EventEmitter();
  @Output() onEchappe: EventEmitter<string> = new EventEmitter();

  @ViewChild('saisieDirective') saisieDirective!: SaisieDirective;
  @ViewChild('inputSaisie', { static: true }) inputSaisie: ElementRef<HTMLInputElement>= {} as ElementRef<HTMLInputElement>;
  
  compteSaisi: string = "";
  controleService: any = null;
  controleFn: string = "";

  constructor(
    private dynamiqueService: DynamiqueService,
  ) {
   }

   ngOnInit(): void {
    // Préchargement du service
    const [serviceName, methodName] = this.atelier.fonction1.split('.');
    this.controleService = this.dynamiqueService.chargerService(serviceName);
    this.controleFn = methodName;       
   }
   
  /**
   * Traitement modification d'image affiché
   * @param bSuivante True pour afficher l'image suivante, false pour afficher l'image courante
   */
  onImageSuivante(bSuivante: boolean) {
    this.onImageChange.emit(bSuivante);
  }

  /**
   * Traitement touche Esc
   * @param event (pas utilisé)
  */
  onTraiterEchappe(event: string) {
    this.onEchappe.emit(event);
  }
  
  /**
   * Traitement après appui sur Entrée
   * @param compte compte saisi
   */
  onTraiterEntree(compte: string) {
    const nomFonction = this.atelier?.fonction1;
    if(nomFonction.length > 0) {
      const msg: string = this.controleService[this.controleFn](compte);
      if(msg.length == 0) {
        this.onValidation.emit(true);
      } else {
        this.onImageChange.emit(false); // On réaffiche l'image
        this.onMessage.emit(msg);
      }  
    }

  }

  /**
   * Action quand un caractère est saisi
   */
  yaSaisie() {
    // On efface le message d'erreur
    this.onMessage.emit("");
  }

  focus() {
    this.inputSaisie.nativeElement.focus();
  }

  razSaisie() {
    this.compteSaisi = "";
    this.inputSaisie.nativeElement.value = ""; 
  }

  setCompte(compte: string) {
    this.inputSaisie.nativeElement.value = compte; 
    this.compteSaisi = compte; 
  }

}

import { Injectable } from '@angular/core';
import { Logger } from '../../../models/Logger.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { APIKEY } from '../../../constantes';
import { FonctionsService } from '../../../services/fonctions.service';
import { ApiUrlService } from '../../../services/api-url.service';

@Injectable({
  providedIn: 'root'
})
export class ChoixSaisiePageService {
  logger: Logger = new Logger(this);
  urlFront: string = "";

  constructor (
    private httpClient: HttpClient,
    private fonctionsService: FonctionsService,
    private apiUrlService: ApiUrlService,
  ) { 
    this.urlFront = fonctionsService.urlBuild(apiUrlService.urlDomaine, "front");
  }

  getListeAtelierDates() {
    this.logger.info("getListeAtelierDates");
    const headers = new HttpHeaders({
      'x-api-key': sessionStorage.getItem(APIKEY) ?? ''
    })
    return this.httpClient.get(this.fonctionsService.urlBuild(this.urlFront, "lstAteliers"),  { headers });

  }
}

import { ComponentRef, Injectable } from '@angular/core';
import { ApiImage } from '../../../models/ApiImage.model';
import { IMAGE_ANNULE, IMAGE_COURANTE, IMAGE_SUIVANTE, OPERATEUR } from '../../../constantes';
import { FonctionsService } from '../../../services/fonctions.service';
import { WebIndexService } from '../../../services/web-index.service';
import { DialoguesService } from '../../dialogues/dialogues.service';
import { catchError, finalize, noop, of } from 'rxjs';
import { StandardTaylorimService } from './standard-taylorim.service';

/**
 * Service pour atelier de saisie standard
 */
@Injectable({
  providedIn: 'root'
})
export class StandardSaisiesService {

  [key: string]: any; // Signature pour appel methode par tableau, pour paramétrage dans Atelier

  constructor(
    private fonctionsService: FonctionsService,
    private webIndexService: WebIndexService,
    private dialoguesService: DialoguesService,
    private standardTaylorimService: StandardTaylorimService,
  ) { }

  /**
   * Pour fonctions où il n'y a rien a faire
   * @returns Chaine vide
   */
  rienAFaire(...args: any[]) {
    noop;
  }

  /**
   * Pour fonctions où il n'y a rien a faire
   * @returns Chaine vide
   */
  rienAFaireStr(...args: any[]) {
    return "";
  }

  /**
   * Pour fonctions où il n'y a rien a faire
   * @returns true
   */
  rienAFaireTrue(...args: any[]) {
    return true;
  }

  /**
   * Pour fonctions où il n'y a rien a faire
   * @returns true
   */
  rienAFaireFalse(...args: any[]) {
    return false;
  }

  /**
   * Enregistrement de la saisie1
   * @param objAtlSaisie Composant appelant, exposant image, zoneSaisieRef, modeSaisie, ainsi que les callback exigé par WebIndexService.enregistrer
   */
  EnregistrerSaisie1(objAtlSaisie: any) {
    let message = "";
    if(objAtlSaisie.image != null) {
      objAtlSaisie.image.saisie1 = objAtlSaisie.zoneSaisieRef!.instance.saisie1;
      objAtlSaisie.image.operateur = sessionStorage.getItem(OPERATEUR) ?? "";
      objAtlSaisie.image.saisieEnregDate = this.fonctionsService.maintenant();
      this.webIndexService["enregistrer"](objAtlSaisie, objAtlSaisie.modeSaisie);
    } else {
      message = "Image à enregister null ?";
    }
    return message;

  }

  /* ***************************************************************************
    Gestion standard des images
  ******************************************************************************/


  /**
   * Clic sur bouton Image suivante
    * @param objAtlSaisie Composant atelier appelant
    * @param event 
   */
  imgSuivOnClicStd(objAtlSaisie: any, event: MouseEvent) {
    objAtlSaisie.logger.info("onClicStd", event);
    objAtlSaisie.zoneSaisieRef!.instance.razSaisie();
    objAtlSaisie.message = "";
    objAtlSaisie.modeSaisie = "";
    if(objAtlSaisie.image != null) {
      this.webIndexService.imageLiberer(objAtlSaisie.siteId, objAtlSaisie.traitementId, objAtlSaisie.atelier, objAtlSaisie.dateTraitement, objAtlSaisie.image.id).subscribe( {
        error: (err) => {
          // On considère que ce n'est pas grave et on continue
          objAtlSaisie.logger.warn("onClicStd err", err);
          objAtlSaisie.changerImage(IMAGE_ANNULE);
        },
        next: (res: any) => {
          // On passe à l'image suivante
          objAtlSaisie.logger.debug("onClicStd res", [res, "Appel changerImage true"]);
          objAtlSaisie.changerImage(IMAGE_ANNULE);
        },
        complete: () => {
          setTimeout( () => { objAtlSaisie.zoneSaisieRef?.instance.focus(); }, 0);          
        },
      })
    } else {
      objAtlSaisie.changerImage(IMAGE_COURANTE);
      setTimeout( () => { objAtlSaisie.zoneSaisieRef?.instance.focus(); }, 0);          
    }
  }

  /**
   * Demande de la prochaine image libre
   * @param objAtlSaisie Composant atelier appelant
   * @param callBackImageOk Callback de traiement de l'image reçue
   */
  chargerImageSuivanteStd(objAtlSaisie: any, exclusion: number, callBackImageOk: Function, callBackImageKo: Function) {    
    objAtlSaisie.logger.info("chargerImageSuivanteStd", [exclusion, callBackImageOk.name]);
    this.webIndexService.imageSuivante(
      objAtlSaisie.siteId, objAtlSaisie.traitementId, objAtlSaisie.atelier, objAtlSaisie.dateTraitement, 
      exclusion
    ).subscribe( {
      error: (err) => {
        objAtlSaisie.logger.debug("chargerImageSuivanteStd err", err);
        callBackImageKo(err);
      },
      next: (res: any) => {
        objAtlSaisie.logger.debug("chargerImageSuivanteStd res", [res, "Appel " + callBackImageOk.name]);
        callBackImageOk(res);
      },
      complete: () => {},
    })  
  }

  /**
   * Changement de l'image
   * @param objAtlSaisie Composant atelier appelant
   * @param mode 0 = image courante, 1 = imageSuivante, 3 = abandon courante
   */
  changerImageStd(objAtlSaisie: any, mode: number) {
    objAtlSaisie.logger.info("changerImageStd", mode);    
    if(objAtlSaisie.modeSaisie == "") {
      objAtlSaisie.logger.info("changerImageStd", mode);
      if(mode == IMAGE_COURANTE) {
        objAtlSaisie.chargerImageSuivante(0, 
          (image: ApiImage) => {
            objAtlSaisie.logger.debug("changerImageStd: chargerImageSuivante callback 1", image);
            if(image != null) {
              objAtlSaisie.image = image;
              objAtlSaisie.imageRef!.instance.image = image;
              objAtlSaisie.image!.saisieDate = objAtlSaisie.fonctionsService.maintenant();
              // Initialisation spécifique
              objAtlSaisie.specifiqueApresChargement(mode);
            }
          },
          () => {
            this.afficherPlusdImageStd(objAtlSaisie);
           }
        );
      } else if(mode == IMAGE_SUIVANTE) {
        if(objAtlSaisie.imageSuivante == null) {
          objAtlSaisie.chargerImageSuivante(0, 
              (image: ApiImage) => {
                objAtlSaisie.logger.debug("changerImageStd: chargerImageSuivante callback 2", image);
                if(image != null) {
                  objAtlSaisie.imageSuivante = image;
                  objAtlSaisie.imageRef!.instance.image = image;  // On affiche l'image suivante
                  // Initialisation spécifique
                  objAtlSaisie.specifiqueApresChargement(mode);
                }
              },
              noop  // On ne fait rien
            );
        } else {  // déjà reservé
          objAtlSaisie.image!.saisieDate = this.fonctionsService.maintenant();
          objAtlSaisie.imageRef!.instance.image = objAtlSaisie.imageSuivante;
          // Initialisation spécifique
          objAtlSaisie.specifiqueApresChargement(mode);
        }
      } else if(mode == IMAGE_ANNULE) {
        if(objAtlSaisie.imageSuivante != null) {
          objAtlSaisie.image = objAtlSaisie.imageSuivante;
          objAtlSaisie.imageSuivante = null;
          objAtlSaisie.imageRef!.instance.image = objAtlSaisie.image;  // On affiche l'image suivante
          objAtlSaisie.image!.saisieDate = this.fonctionsService.maintenant();
          // Initialisation spécifique
          objAtlSaisie.specifiqueApresChargement(mode);
        } else {
          objAtlSaisie.chargerImageSuivante(objAtlSaisie.image!.id, 
            (image: ApiImage) => {
              objAtlSaisie.logger.debug("changerImageStd: chargerImageSuivante callback 3", image);
              if(image != null) {
                objAtlSaisie.image = image;
                objAtlSaisie.imageRef!.instance.image = image;  // On affiche l'image suivante
                objAtlSaisie.image!.saisieDate = this.fonctionsService.maintenant();
                // Initialisation spécifique
                objAtlSaisie.specifiqueApresChargement(mode);
              }
            },
            () => {
              objAtlSaisie.afficherPlusdImage();
            }
          );
        }
      } else {
        objAtlSaisie.logger.error("mode inconnu ", mode);
      }
    }
  }

  /**
   * Callback appelé quand l'enregistrement échoue
   * @param objAtlSaisie Composant atelier appelant
   * @param err Callback en cas d'enregistrement ko
   */
  callBackEnregKoStd(objAtlSaisie: any, err: any) {
    objAtlSaisie.message = err.error.message; // déjà loggué
  }

  /**
   * Callback appelé quand l'enregistrement réussi
   * @param objAtlSaisie Composant atelier appelant
   */
  callBackEnregOkStd(objAtlSaisie: any) {
    objAtlSaisie.logger.info("callBackEnregOkStd", objAtlSaisie.image);
    objAtlSaisie.image.historique = objAtlSaisie.zoneSaisieRef!.instance.historique();
    objAtlSaisie.zoneSaisieRef!.instance.razSaisie();
    objAtlSaisie.decStock.emit(true);
    objAtlSaisie.historiqueRef!.instance.ajouterImageSaisie(objAtlSaisie.image).subscribe({
      next: () => {
        if(objAtlSaisie.imageSuivante != null) {
          objAtlSaisie.logger.debug("callBackEnregOkStd", objAtlSaisie.imageSuivante);
          objAtlSaisie.image = objAtlSaisie.imageSuivante;
          objAtlSaisie.image.saisieDate = this.fonctionsService.maintenant();
          objAtlSaisie.imageRef!.instance.image = objAtlSaisie.image;
          objAtlSaisie.imageSuivante = null;
          objAtlSaisie.specifiqueApresChargement(IMAGE_COURANTE);  // Image suivante devient courant
        } else {
          objAtlSaisie.logger.debug("callBackEnregOkStd imageSuivante null");
          objAtlSaisie.modeSaisie = "";
          objAtlSaisie.changerImage(IMAGE_COURANTE);
        }
      }
    });


  }

  /**
   * Gestion d'une demande de modification d'une image saisie
   * @param objAtlSaisie Composant atelier appelant
   * @param id id de l'image à modifier
   */
  saisieModifierStd(objAtlSaisie: any, id: number) {
    // annuler reservation image suivante
    if(objAtlSaisie.imageSuivante != null) {
      this.webIndexService.imageLiberer(objAtlSaisie.siteId, objAtlSaisie.traitementId, objAtlSaisie.atelier, objAtlSaisie.dateTraitement, objAtlSaisie.imageSuivante.id).pipe(
        catchError((err) => {
          objAtlSaisie.logger.warn("saisieModifierStd err", err);
          return of(null);
        }),
        finalize(() => {
          objAtlSaisie.imageSuivante = null;
          objAtlSaisie.saisieModifier2(id);
        })
      ).subscribe((res: any) => {
        // On ne fait rien de plus
      });    
    } else {
      objAtlSaisie.saisieModifier2(id);
    }
  }

  /** 
   * Action après libération de l'image préchargée
   * @param objAtlSaisie Composant atelier appelant
   * @param id id de l'image à modifier
  */
  saisieModifier2Std(objAtlSaisie: any,id: number) {
    // annuler reservation en cours
    if(objAtlSaisie.image != null) {
      this.webIndexService.imageLiberer(objAtlSaisie.siteId, objAtlSaisie.traitementId, objAtlSaisie.atelier, objAtlSaisie.dateTraitement, objAtlSaisie.image.id).pipe(
        catchError( (err) => {
          objAtlSaisie.logger.warn("saisieModifier2Std err", err);
          return of(null);
        }),
        finalize( () => {
          objAtlSaisie.saisieModifier3(id);
        })
      ).subscribe();
    } else {
      objAtlSaisie.saisieModifier3(id);
    }
  }
  
  /**
   * Action après libération de l'image en cours
   * @param objAtlSaisie Composant atelier appelant
   * @param id id de l'image à modifier
   */
  saisieModifier3Std(objAtlSaisie: any, id: number) {
    // charger l'image à modifier si modifiable
    this.webIndexService.getImageModifiable(objAtlSaisie.siteId, objAtlSaisie.traitementId, objAtlSaisie.atelier, objAtlSaisie.dateTraitement, id).subscribe( {
      error: (err) => {
        this.saisieModifier3KoStd(objAtlSaisie, err);
        },
      next: (res: any) => {
        this.saisieModifier3OkStd(objAtlSaisie, res);
      },
      complete: () => {
      },

    })
  }
  
  /**
   * Action standard si une modification est possible
   * @param objAtlSaisie Composant atelier appelant
   * @param res Résultat de l'api
   */
  saisieModifier3OkStd(objAtlSaisie: any, res: any) {
    // On affiche l'image
    objAtlSaisie.image = res;
    objAtlSaisie.imageRef!.instance.image = objAtlSaisie.image;
    objAtlSaisie.zoneSaisieRef?.instance.setSaisie1(objAtlSaisie.image!.saisie1);
    objAtlSaisie.zoneSaisieRef!.instance.focus();
    objAtlSaisie.message = "";
    objAtlSaisie.modeSaisie = "M";
    objAtlSaisie.specifiqueApresChargement(IMAGE_COURANTE);
  }

  /**
   * Action standard si une modification n'est pas possible
   * @param objAtlSaisie Composant atelier appelant
   * @param err Erreur de l'api
   */
  saisieModifier3KoStd(objAtlSaisie: any, err: any) {
    // On considère qu'on ne peut plus modifier
    objAtlSaisie.logger.warn("saisieModifier3Std err", err);
    this.dialoguesService.messageBox(`Atelier ${objAtlSaisie.atelier.libelle}`, 
      "La modification de la saisie n'est pas possible",
      () => {
        objAtlSaisie.changerImage(IMAGE_COURANTE);
      }, 
      3000
    );
  }


  /**
  * Foncton de libération d'imge dans un atelier
  * @param objAtlSaisie Composant atelier appelant
  */
  imagesLibererStd(objAtlSaisie: any) {
    if(objAtlSaisie.imageSuivante != null) {
      this.webIndexService.imageLiberer(
        objAtlSaisie.siteId, objAtlSaisie.traitementId, objAtlSaisie.atelier, objAtlSaisie.dateTraitement, 
        objAtlSaisie.imageSuivante.id
      ).pipe(
        catchError( (err) => {
          objAtlSaisie.logger.warn("imagesLibererStd err suivante", err);
          return of(null);
        }),
        finalize( () => {})
      ).subscribe();
    }
    if(objAtlSaisie.image != null) {
      this.webIndexService.imageLiberer(
        objAtlSaisie.siteId, objAtlSaisie.traitementId, objAtlSaisie.atelier, objAtlSaisie.dateTraitement, 
        objAtlSaisie.image.id
      ).pipe(
        catchError( (err) => {
          objAtlSaisie.logger.warn("imagesLibererStd err encours", err);
          return of(null);
        }),
        finalize( () => {})
      ).subscribe();
    }
  }
  
  /**
   * Fonction d'affichage plus d'image à saisir
   * @param objAtlSaisie Composant atelier appelant
   */
  afficherPlusdImageStd(objAtlSaisie: any) {
    this.dialoguesService.messageBox(`Atelier ${objAtlSaisie.atelier.libelle}`, 
      "Aucune image à vidéocoder",
      () => {
        this.webIndexService.majStock(objAtlSaisie.siteId, objAtlSaisie.traitementId, objAtlSaisie.atelier, objAtlSaisie.dateTraitement).subscribe({
          error: (err) => {
            objAtlSaisie.logger.debug("afficherPlusdImageStd majStock err", err);
          },
          next: (res: any) => {
          },
          complete: () => {
            objAtlSaisie.router.navigate(["choix"]);
          },
        });
      }, 
      2000
    );
  }

  /****************************************************************************
   * Fonctions pour atelier standard
   ****************************************************************************/
  /**
   * Initialisation après chargement données pour atl standard
   * @param objAtlSaisie 
   */
  stdInit(objAtlSaisie: any) {
    objAtlSaisie.zoneSaisieRef!.instance.focus();
  }

  /****************************************************************************
   * Fonctions pour atelier cmc7 standard
   ****************************************************************************/
  /**
   * Initialisation des champs cmc7 standard
   * @param objAtlSaisie 
   */
  cmc7Init(objAtlSaisie: any) {
    objAtlSaisie.zoneSaisieRef!.instance.saisie4 = objAtlSaisie.image.saisie4;
    objAtlSaisie.zoneSaisieRef!.instance.saisie3 = objAtlSaisie.image.saisie3;
    objAtlSaisie.zoneSaisieRef!.instance.saisie2 = objAtlSaisie.image.saisie2;
    objAtlSaisie.zoneSaisieRef!.instance.saisie1 = objAtlSaisie.image.saisie1;
    objAtlSaisie.zoneSaisieRef!.instance.saisie9 = objAtlSaisie.image.saisie9;
    objAtlSaisie.zoneSaisieRef!.instance.saisie5 = objAtlSaisie.image.saisie5;
    objAtlSaisie.zoneSaisieRef!.instance.saisie8 = objAtlSaisie.image.saisie8;
    objAtlSaisie.zoneSaisieRef!.instance.nbSaisieCle = 0;
    objAtlSaisie.zoneSaisieRef!.instance.saisie9Prc = "";
    setTimeout(
      () => { 
        objAtlSaisie.zoneSaisieRef!.instance.focusService[objAtlSaisie.zoneSaisieRef!.instance.focusFn](objAtlSaisie.zoneSaisieRef!.instance, 0); 
      }, 0
    );
    
  }

  /**
   * Fonction standard de complétion des zones cmc7
   * @param objZoneSaisieRef 
   * @param noZone 
   */
  cmc7Pad(objZoneSaisieRef: any, noZone: number) {
    switch(noZone) {
      case 4:
        objZoneSaisieRef.saisie4 = this.fonctionsService.padL(objZoneSaisieRef.saisie4.trim(), 7, "0");
        break;
      case 3:
        objZoneSaisieRef.saisie3 = this.fonctionsService.padL(objZoneSaisieRef.saisie3.trim(), 12, "0");
        break;
      case 2:
        objZoneSaisieRef.saisie2 = this.fonctionsService.padL(objZoneSaisieRef.saisie2.trim(), 12, "0");
        break;
      case 9:
        objZoneSaisieRef.saisie9 = this.fonctionsService.padL(objZoneSaisieRef.saisie9.trim(), 2, "0");
        break;
    }
  }

  /**
   * Enregistrement standard saisie cmc7
   * @param objAtlSaisie 
   * @returns 
   */
  cmc7Enregistrer(objAtlSaisie: any) {
    let message = "";
    if(objAtlSaisie.image != null) {
      objAtlSaisie.image.saisie4 = objAtlSaisie.zoneSaisieRef!.instance.saisie4;
      objAtlSaisie.image.saisie3 = objAtlSaisie.zoneSaisieRef!.instance.saisie3;
      objAtlSaisie.image.saisie2 = objAtlSaisie.zoneSaisieRef!.instance.saisie2;
      objAtlSaisie.image.saisie1 = objAtlSaisie.zoneSaisieRef!.instance.saisie1;
      objAtlSaisie.image.saisie5 = objAtlSaisie.zoneSaisieRef!.instance.saisie5;
      objAtlSaisie.image.saisie8 = objAtlSaisie.zoneSaisieRef!.instance.saisie8;
      objAtlSaisie.image.saisie9 = objAtlSaisie.zoneSaisieRef!.instance.saisie9;
      objAtlSaisie.image.operateur = sessionStorage.getItem(OPERATEUR) ?? "";
      objAtlSaisie.image.saisieEnregDate = this.fonctionsService.maintenant();
      this.webIndexService["enregistrer"](objAtlSaisie, objAtlSaisie.modeSaisie);
    } else {
      message = "Image à enregister null ?";
    }
    return message;
  }


}

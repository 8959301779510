<div class="loginpage" [ngStyle]="{'cursor': appelKeyCloak ? 'wait' : 'default'}">
  <div class="container-fluid">
    <div class="row haut"></div>
    <div class="row cadre">
      <div class="col col-md-6"></div>
      <div class="col col-md-4">
        <div class="login">
          <div class="row h-10"></div>
          <div class="row h-30">
            <h3 class="texte">Accédez à</h3>
          </div>
          <div class="row h-30 text-center">
            <h2>{{ nomProjet }}</h2>
          </div>
          <div class="row h-20 text-center">
            <div class="col col-md-2"></div>
            <div class="col col-md-8">
              <button type="button"
                class="btn btn-primary"
                [ngClass]="{ 'btn': true, 'btn-primary': true, 'animer': appelKeyCloak}"
                [ngStyle]="{'cursor': appelKeyCloak ? 'wait' : 'pointer'}"
                [disabled]="appelKeyCloak"
                (click)="connexion()"
              >
                {{ appelKeyCloak ? 'Appel Keycloak...' : 'En cliquant ici'}}
              </button>
            </div>
          </div>
        </div>    
      </div>
    </div>
    <div class="row titre">
      <div class="row">
        <div class="col col-md-1"></div>
        <div class="col col-md-3">
          <div class="row">
            <h2 class=" texte-blanc">{{ nomProjet }}</h2>          
          </div>
          <div class="row version">
            Version {{ version }}<br />api {{ apiVersion }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col col-md-1"></div>
        <div class="col">
          <h3 class=" texte-blanc">Application de vidéocodage<br /></h3>
        </div>
      </div>

    </div>
    <div class="row bas"></div>
  </div>
  <footer class="row">
    <div class="col col-md-11 text-right">
      <div class="logo-docaposte"></div>
    </div>
    <div class="col col-md-1"></div>
  </footer>
</div>
import { ElementRef, Injectable } from '@angular/core';
import { BoutonInfoModel } from '../../../../models/BoutonInfo.model';
import { IMAGE_COURANTE, OPERATEUR } from '../../../../constantes';
import { TYPECHQ, TYPEREM, TYPESEP, TYPETKL } from '../../standard/ateliers/atl-taylorim/TimCconstantes';
import { FonctionsService } from '../../../../services/fonctions.service';
import { WebIndexService } from '../../../../services/web-index.service';
import { StandardTaylorimService } from '../../standard/standard-taylorim.service';
import { lastValueFrom } from 'rxjs';
import { ControleSaisieStdService } from '../../standard/controle-saisie-std.service';
import { Agence } from '../../../../models/Agence';

/**
 * Classes des fonctions spécifiques pour les ateliers Lbp
 */
@Injectable({
  providedIn: 'root'
})
export class LbpSaisiesService {
  static ACTION_FOCUS: number = 0;
  static ACTION_CARSUIVANT: number = 1;
  static ACTION_GAUCHE: number = 2;
  static ACTION_DROITE: number = 3;
  static ACTION_VALIDER: number = 5;


  [key: string]: any; // Signature pour appel methode par tableau, pour paramétrage dans Atelier

  constructor(
    private fonctionsService: FonctionsService,
    private webIndexService: WebIndexService,
    private standardTaylorimService: StandardTaylorimService,
    private controleSaisieStdService: ControleSaisieStdService,
  ) { }

  /*===========================================================================
    Atelier tkl
  =============================================================================*/
  /**
   * Initialisation des zone de saisies date et heure standard
   * @param objAtlSaisie Composant atelier de saisie
   * @param mode 
   */
  tklDateHeureInitApresChargement(objAtlSaisie: any, mode: number) {
    if(mode == IMAGE_COURANTE) {
      objAtlSaisie.zoneSaisieRef!.instance.dateSaisie = objAtlSaisie.image?.saisie3; // Date par défaut
      objAtlSaisie.zoneSaisieRef!.instance.inputSaisied.nativeElement.focus();
    } else {
      console.log("**** image en cours pas modifié, on ne fait rien")
    }
  }

  /**
   * Enregistrement de la saisie de TKL
   * @param objAtlSaisie 
   * @returns 
   */
  tklDateHeureEnregistrer(objAtlSaisie: any) {
    let message = "";
    if(objAtlSaisie.image != null) {
      objAtlSaisie.image.saisie1 = objAtlSaisie.zoneSaisieRef!.instance.dateSaisie;
      objAtlSaisie.image.saisie2 = objAtlSaisie.zoneSaisieRef!.instance.heureSaisie;
      objAtlSaisie.image.operateur = sessionStorage.getItem(OPERATEUR) ?? "";
      objAtlSaisie.image.saisieEnregDate = this.fonctionsService.maintenant();
      this.webIndexService["enregistrer"](objAtlSaisie, objAtlSaisie.modeSaisie);
    } else {
      message = "Image à enregister null ?";
    }
    return message;
  }

  /*===========================================================================
    Atelier REM Reference
  =============================================================================*/
  /**
   * Contrôle la saisie dee référence entreprise
   * @param objZoneSaisieRef Composant contenant les champs de saisie
   * @returns 
   */
  ctrlRemReference(objZoneSaisieRef: any): string {
    const regex = /^[A-Z0-9\-\/\* ]*$/;
    return regex.test(objZoneSaisieRef.saisie1) ? "" : "Les caratères valides sont ABCDEFGHIJKLMNOPQRSTUVWXYZ 0123456789-/*";
  }

  /*===========================================================================
    Ateliers saisie montants
  =============================================================================*/

  /**
  *  Action sur bouton fraude
  * @param objAtlSaisie Composant atelier de saisie
  * @param btnIfon Informations sur le bouton cliqué
  */
  mtChqBoutonClic(objAtlSaisie: any, btnInfo: BoutonInfoModel) {
    if(btnInfo.valeur == "1") {
      objAtlSaisie.zoneBoutonRef.instance.setValeur(0, "1");
    } else {
      objAtlSaisie.zoneBoutonRef.instance.setValeur(0, "0");
    }
    // focus montant
    objAtlSaisie.zoneSaisieRef?.instance.focus();
  }

  /**
  * Enregistrement de la saisie d'un montant
  * @param objAtlSaisie Composant appelant, exposant image, zoneSaisieRef, modeSaisie, ainsi que les callback exigé par WebIndexService.enregistrer
  */
  mtChqEnregistrer(objAtlSaisie: any) {
    let message = "";
    if(objAtlSaisie.image != null) {
      objAtlSaisie.image.saisie1 = objAtlSaisie.zoneSaisieRef!.instance.saisie1;
      objAtlSaisie.image.saisie2 = objAtlSaisie.zoneSaisieRef!.instance.saisie2;
      objAtlSaisie.image.operateur = sessionStorage.getItem(OPERATEUR) ?? "";
      objAtlSaisie.image.saisieEnregDate = this.fonctionsService.maintenant();
      this.webIndexService["enregistrer"](objAtlSaisie, objAtlSaisie.modeSaisie);
    } else {
      message = "Image à enregister null ?";
    }
    return message;
  }
  
  /**
   * Initialisation des ateliers chq mt pour Lbp
   * Bouton Fraude
   * Montant original pour vérification saisie
   * @param objAtlSaisie Composant atelier de saisie
   */
  mtChqInitApresChargement(objAtlSaisie: any, mode: number) {
    if(mode == IMAGE_COURANTE) {
      objAtlSaisie.zoneSaisieRef!.instance.montantPrec = objAtlSaisie.image?.saisie3; // Pour atl vérification
      objAtlSaisie.zoneSaisieRef!.instance.setSaisie2(objAtlSaisie.image.saisie2);
      objAtlSaisie.zoneSaisieRef.instance.focus();
    } else {
      console.log("**** image en cours pas modifié, on ne fait rien")
    }
  }

  /**
   * Controle de saisie de compte sur 12 caractères
   * @param objZoneSaisieRef 
   * @param sZone2 Compte à contrôler
   * @param lstCentres Liste des codes centres valides sous forme Agence
   * @param callBackOk Callback appelé si le compte est bon
   * @param callbackKo Callback appelé si le compte n'est pas bon
   * @returns 
   */
  lbpZone2EstValide(objZoneSaisieRef: any, sZone2: string, lstCentres: Agence[], 
    callBackOk: Function, callbackKo: Function
  ) {
    let sCptLettre: string;
    sCptLettre = sZone2; // Sans conversion
    if (sZone2.length !== 12) {
      callbackKo("La zone2 ne fait pas 12 caractères");
      return;
    } 
    if (this.fonctionsService.estNumerique(sZone2)) {
      // Compte cmc7
      if (sZone2.startsWith("0")) {
        if (this.lbpCleCompteOk(sZone2, false, lstCentres)) {
          sCptLettre = this.lbpCompteNum2Lettre(sZone2);
        } else {
          callbackKo("Compte numérique invalide !");
          return;
        }
      } else {
        callbackKo("Un compte numérique commence par 0 !");
      }
    } else if (this.fonctionsService.estNumerique(sZone2.slice(-1))) {

      // Compte CNE
      if (!this.lbpCleCompteCNEOk(sZone2.slice(1))) {
        callbackKo("Compte CNE invalide !");
        return;
      }
    } else {
      // Compte ccp
      if (!sZone2.startsWith("0")) {
        callbackKo("Un compte CCP commence par 0 !");
        return;
      } else {
        sZone2 = this.lbpConvertirCentre(sZone2, lstCentres);
        if (!this.lbpCleCompteOk(sZone2, true, lstCentres)) {
          callbackKo("Clé compte invalide !");
          return;
        }
      }
    }
    this.lbpCompteExiste(objZoneSaisieRef, sCptLettre, callBackOk, callbackKo);

  }
    
  lbpCompteExiste(objZoneSaisieRef: any, noCptLettre: string, callBackOk: Function, callBackKo: Function) {
    let mapParams = new Map<string, string>();
    mapParams.set("noCompte", noCptLettre);
    this.webIndexService.appelBackendExterne(
      "compteExiste", 
      objZoneSaisieRef.siteId, objZoneSaisieRef.traitementId, objZoneSaisieRef.atelier, objZoneSaisieRef.dateTraitement, 
      mapParams        
    ).subscribe({
        error: (err) => {
          if(err.status == 404) {
            callBackKo(`Le compte ${this.fonctionsService.right(noCptLettre, 11)} n'existe pas dans la base`);
          } else {
            callBackKo("Oups!  Erreur: " + err.toString());
          }
        },
        next: (res: any) => {
          callBackOk(objZoneSaisieRef);
        },
        complete: () => { }  
    }) ;

  }

  /**
   * Conversion d'une zone2 en compte avec lettre
   * @param sZ2 
   * @returns 
   */
  lbpCompteNum2Lettre(sZ2: string): string {
    const sLettres = "ABCDEFGHJKLMNPRSTUVWXYZ";
    const part1 = sZ2.substring(0, 8);
    const index = parseInt(sZ2.substring(8, 10), 10);
    const part2 = sLettres.charAt(index);
    const part3 = "0" + sZ2.substring(sZ2.length - 2);

    return part1 + part2 + part3;
  }

  lbpConvertirCentre(sCompte: string, lstCentres: Agence[]): string {
    let sNoCentre: string | undefined;
    let lPosition = 0;
    let lPos: number;

    sCompte = sCompte.trim().toUpperCase();

    for (let i = 0; i < lstCentres.length; i++) {
      lPos = sCompte.indexOf(lstCentres[i].infoGuichet1);
      if (lPos > -1) {
        sNoCentre = lstCentres[i].codeGuichet;
        lPosition = lPos + 1;
        break;
      }
    }

    if (lPosition === 0) { // Essai O 0
      for (let i = 0; i < lstCentres.length; i++) {
        lPos = sCompte.indexOf(lstCentres[i].infoGuichet1.replace(/O/g, "0"));
        if (lPos > -1) {
          sNoCentre = lstCentres[i].codeGuichet;
          lPosition = lPos + 1;
          break;
        }
      }
    }

    if (lPosition === 1) { // Un 0 a été saisi après le centre
      sCompte = sCompte.substring(3) + sNoCentre;
    } else if (lPosition === 2) { // Un 0 a été ajouté automatiquement devant !
      // Trouvé en début
      sCompte = "0" + sCompte.substring(4) + sNoCentre;
    } else if (lPosition > 2) {
      sCompte = sCompte.substring(0, lPosition - 1) + sCompte.substring(lPosition + 2) + sNoCentre;
    }

    return sCompte;
  }  

  lbpCleCompteOk(sZ2: string, bAvecLettre: boolean, lstCentres: Agence[]): boolean {
    const sLettres = "ABCDEFGHJKLMNPRSTUVWXYZ";
    let bOk: boolean;
    let sCle: string, iCle: number = 0, lCpt: number, lPos: number, sCodeCentre: string;
    if (!this.fonctionsService.estNumerique(sZ2.substring(1, 8))) {
      bOk = false;
    } else {
      try {
        lCpt = parseInt(sZ2.substring(10, 12)) * 1000000 + parseInt(sZ2.substring(1, 8));
        if (isNaN(lCpt)) {
          bOk = false;
        } else {
          if (lCpt === 0) {
            bOk = false;
          } else {
            sCle = sZ2.substring(8, 10);
            if (!this.fonctionsService.estNumerique(sCle.charAt(0)) && sCle.charAt(1) === "0"
              && this.fonctionsService.estNumerique(sZ2.charAt(7))) {
              lPos = sLettres.indexOf(sCle.charAt(0));
              if (lPos > -1) {
                iCle = lPos;
                bOk = true;
              } else {
                bOk = false;
              }
            } else {
              if (bAvecLettre) {
                bOk = false;
              } else {
                iCle = parseInt(sCle);
                if (isNaN(iCle)) {
                  bOk = false;
                } else {
                  bOk = true;
                }
              }
            }
            if (bOk) {
              if (iCle === lCpt % 23) {
                if (bAvecLettre) {
                  sCodeCentre = sZ2.substring(sZ2.length - 3);
                } else {
                  sCodeCentre = "0" + sZ2.substring(sZ2.length - 2);
                }
                if (this.lbpCodeCentreOk(sCodeCentre, lstCentres)) {
                  bOk = true;
                } else {
                  bOk = false;
                }
              } else {
                bOk = false;
              }
            }
          }
        }
      } catch (e) {
        bOk = false;
      }
    }
    return bOk;
  }

  lbpCleCompteCNEOk(sZ2: string): boolean {
    const sLettres = "ABCDEFGHJKLMNPRSTUVWXYZ";
    let bOk: boolean;
    let sCle: string, iCle: number, lCpt: number, lPos: number;

    if (!this.fonctionsService.estNumerique(sZ2.substring(0, 10))) {
      bOk = false;
    } else if (this.fonctionsService.estNumerique(sZ2.substring(sZ2.length - 1))) {
      bOk = false;
    } else {
      lPos = sLettres.indexOf(sZ2.substring(sZ2.length - 1)) + 1;
      if (lPos <= 0) {
        bOk = false;
      } else {
        iCle = lPos - 1;
        lCpt = parseInt(sZ2.substring(0, 10));
        if (lCpt === 0) {
          bOk = false;
        } else {
          if (iCle === parseInt(this.fonctionsService.modulo(lCpt, 23))) {
            bOk = true;
          } else {
            bOk = false;
          }
        }
      }
    }
    return bOk;
  }

  /**
   * Vérifie l'existence du code centre dans le référenctiel
   * @param sCode 
   * @param lstCentres 
   * @returns 
   */
  lbpCodeCentreOk(sCode: string, lstCentres: Agence[]): boolean {
    let bOk = false;
    for (let i = 0; i < lstCentres.length; i++) {
      if (lstCentres[i].codeGuichet === sCode) {
        bOk = true;
        break;
      }
    }
    return bOk;
  }


}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthConfig, OAuthErrorEvent, OAuthService, OAuthSuccessEvent, ValidationHandler } from 'angular-oauth2-oidc';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { oAuthConfig } from '../../../../environments/environment';
import { APIKEY } from '../../../constantes';
import { Identification } from '../../../models/Identification.model';
import { FonctionsService } from '../../../services/fonctions.service';
import { ApiUrlService } from '../../../services/api-url.service';
import { DialoguesService } from '../../dialogues/dialogues.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  // Configuration keycloak
  authConfig: AuthConfig = oAuthConfig;
  urlFront: string = "";

  public subIdentification$ = new BehaviorSubject<any>(null);  // Subject pour réception identification pour premier appel
  public subIdentification2$ = new BehaviorSubject<any>(null);  // Subject pour réception identification pour second appel avec deconnexion
  public subKeycloak$ = new BehaviorSubject<any>(null);  // Subject pour guetter resultat keyclaok

  constructor(
    private oauthService: OAuthService,
    private router: Router,
    private httpClient: HttpClient,
    private fonctionsService: FonctionsService,
    private apiUrlService: ApiUrlService,
    private dialoguesService: DialoguesService,

  ) {
    this.urlFront = apiUrlService.urlDomaine;
    if(sessionStorage.getItem(APIKEY)) {
      // La clé existe, on vérifie si c'est valide (cas refresh)
      this.sessionActive(sessionStorage.getItem(APIKEY)!).subscribe({
        error: (err: any) => {
          this.loginSuite(true);  // reloguer
        },
        next: () => {
          this.loginSuite(false);  // clé encore valide
        },
        complete: () => {},        
      });
    } else {
      this.loginSuite(true);  // reloguer
    }
  }

  /**
   * Suite constructeur, après attente api
   * @param keycloak true pour se reloguer
   */
  loginSuite(keycloak: boolean) {
    if(keycloak) {
      this.observerOAuthEvents();
      //console.log("login", window.location.origin + '/' + oAuthConfig.redirectUri, window.location.origin + '/' + oAuthConfig.postLogoutRedirectUri)
      this.authConfig.redirectUri = window.location.origin + '/' + oAuthConfig.redirectUri;
      //console.log("redirectUri", this.authConfig.redirectUri)
      this.authConfig.postLogoutRedirectUri = window.location.origin + '/' + oAuthConfig.postLogoutRedirectUri;
      //console.log("postLogoutRedirectUri", this.authConfig.postLogoutRedirectUri)
      this.oauthService.configure(this.authConfig);
///    this.oauthService.loadDiscoveryDocumentAndTryLogin();
      this.oauthService.loadDiscoveryDocument()
        .then(() => this.oauthService.tryLogin())
        .then(() => {
          if (!this.oauthService.hasValidAccessToken()) {
            // 2. SILENT LOGIN:
            // Try to log in via silent refresh because the IdServer
            // might have a cookie to remember the user, so we can
            // prevent doing a redirect:
            /*
            this.oauthService.silentRefresh()
              .catch(result => {
                console.log("refresh ko", result, result.reason)
                // Subset of situations from https://openid.net/specs/openid-connect-core-1_0.html#AuthError
                // Only the ones where it's reasonably sure that sending the
                // user to the IdServer will help.
                const errorResponsesRequiringUserInteraction = [
                //'interaction_required',
                //'login_required',
                //'account_selection_required',
                //'consent_required',
                'silent_refresh_timeout',
                'code_error'
                ];
    
                if (result && errorResponsesRequiringUserInteraction.indexOf(result.type) >= 0) {
                  console.log("refresh ko 2")
    
                  // 3. ASK FOR LOGIN:
                  // At this point we know for sure that we have to ask the
                  // user to log in, so we redirect them to the IdServer to
                  // enter credentials:
                  //this.oauthService.initCodeFlow();
                  router.navigate(["login"])
                }
              });
            */
            this.router.navigate(["login"])
          }
        });
    } else {
      this.router.navigate(["choix"]);
    }
  }

  /**
   * Lancement du processus keycloak
   */
  public login(): void {
    console.log(">>>> initcodeflow") 
    this.subKeycloak$.next("ON");
    this.oauthService.initCodeFlow();
  }

  /**
   * Déconnexion de l'api et de keycloak
   */
  public logout(): void {
    this.apiLogout().subscribe({
      complete: ()=> {
        this.oauthService.logOut(false); 
        sessionStorage.clear();
        this.router.navigate(["login"]);
      }
    });
    
  }

  /**
   * Lancement de l'écoute de angular-oauth-oidc pour récupérer le token
   */
  public observerOAuthEvents() {
    this.oauthService.events.subscribe(event => {
      console.log("observerOAuthEvents event", event)
      /* on utilise keycloak pour l'identification initiale uniquement
       De toute façcon le code ci-dessous boucle
      if(event.type == 'token_expires') {
        console.log('token_expires');
        try {
          this.oauthService.silentRefresh();
        } catch(err) {
          console.log("oauthService.SilentRefrsh ", err);
        }
      } else if(event.type == "invalid_nonce_in_state") {
        this.subErreur$.next('Une erreur keycloak est survenue: invalid_nonce_in_state');
      } else {
        */
        if (event instanceof OAuthErrorEvent) {            
          //console.error(event);
          if(event.type == "silent_refresh_timeout") {
            console.log("keycloak silent_refresh_timeout")
          } else if (event.type == "discovery_document_load_error") {
            const ev: any = event;  // pour accéder aux propriétés
console.log("event*****", event, event.reason, ev.reason.message)            
            this.dialoguesService.messageBox(
              "Webindexation: Identification",
              "Il semble qu'il y ait un problème avec keycloak.<br>" + ev.reason.message,
              () => {
                this.subKeycloak$.next("OFF");
                this.router.navigate(["login"]);
              },
              0);
          } else {
// ne fonctionne pas ???
            this.subIdentification$.error({ code: -1, message: "Keycloak: " + event.type});

          }
        } else if (event instanceof OAuthSuccessEvent) {
          if (event.type === 'token_received') {
            // Récupérer token et envoi à api
            console.log('token_received');
            this.traiterAccessToken(false);
          } else {
  //          console.warn(event.type);
          }
        }
      //}
    });
  }

  public traiterAccessToken(deconnecter: boolean) {
    const token = this.oauthService.getAccessToken();
    this.getApiKey(token, deconnecter).subscribe({
      error: (err: any) => {
        if (err.status == 0) { // A priori le serveur api n'est pas en ligne
          if(!deconnecter) {
            this.subIdentification$.error({ code: err.status, message: "Le serveur API ne répond pas."});
          } else {
            this.subIdentification2$.error({ code: err.status, message: "Le serveur API ne répond pas."});
          }
        } else {
          console.log('error api', err);
          if(!deconnecter) {
            this.subIdentification$.error({ code: err.status, message: err.error.message});
          } else {
            this.subIdentification2$.error({ code: err.status, message: err.error.message});
          }
        }
      },
      next: (res: Identification) => {
        if(!deconnecter) {
          this.subIdentification$.next(res);
        } else {
          this.subIdentification2$.next(res);
        }
      },
      complete: () => {},      
    });
  }

  /**
   * Echange token contre Apikey
   * @param token accessToken reçu de keycloak
   * @param deconnecter true s'il faut déconnecter un autre poste avec le même login
   * @returns
   */
  public getApiKey(token: string, deconnecter: boolean): Observable<any> {
    const apiUrl = this.fonctionsService.urlBuild(this.urlFront, "keycloakToken");
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    headers = headers.append('Deconnecter', deconnecter.toString());
    return this.httpClient.get(apiUrl, {headers});
  }

  /**
   * Vérifier session ok
   * @param apiKey api key en cours à vérifier
   * @returns Observable
   */
  public sessionActive(apiKey: string): Observable<any> {
    const apiUrl = this.fonctionsService.urlBuild(this.urlFront, "sessionActive");
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('x-api-key', apiKey);
    return this.httpClient.get(apiUrl, {headers});
  }

  /**
   * Appel api de déconnexion
   * @returns Observable
   */
  public apiLogout(): Observable<any> {
    const apiUrl = this.fonctionsService.urlBuild(this.urlFront, "deconnecter");
    const headers = new HttpHeaders({
      'x-api-key': sessionStorage.getItem(APIKEY) ?? '',
    })
    return this.httpClient.get(apiUrl, {headers, observe: 'response'});
  }

}

<div class="row">
  <div class="col-md-12 img-droite">
    <img 
      [width]="atelier?.imageLargeur"
      [height]="atelier?.imageHauteur"
      [src]="image?.image64"
      [alt]="image?.id" 
      [ngbTooltip]="'Image ' + image?.id"
    />
  </div>
</div>

import { ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { ApiImage } from '../../../../../models/ApiImage.model';
import { CommonModule } from '@angular/common';
import { Observable } from 'rxjs';

/**
 * Composant pour afficher les 50 derniers éléments saisi, qui sont mofifiables
 */
@Component({
  selector: 'app-historique',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './historique.component.html',
  styleUrl: './historique.component.scss'
})
export class HistoriqueComponent {
  @Output() onElementClick: EventEmitter<number> = new EventEmitter();

  lstImages: ApiImage[] = [];
  imgSelectionId: number = 0;
  /** Compteur du nombre de saisie dans la session */
  nbSaisi: number = 0;

  constructor(private cdr: ChangeDetectorRef) {}

  elementClick(event: MouseEvent, id: number) {
    this.imgSelectionId = id;
    this.onElementClick.emit(id);
  }

  /**
   * Ajout d'une image saisie dans la liste
   * @param image 
   */
  ajouterImageSaisie(image: ApiImage): Observable<void> {
    return new Observable(observer => {
      const index = this.lstImages.findIndex((ai) => ai.id == image.id);
      if(index == -1) {
        this.nbSaisi ++;
        this.lstImages.push(image);
        const derniereLigne = document.querySelector('.li-saisie:last-child');
        if (this.lstImages.length > 50) {
          this.lstImages = this.lstImages.slice(-50);
          this.cdr.detectChanges();
        }
        // Attendre que la liste soit affichée avant de faire défiler jusqu'à la dernière ligne
        setTimeout(() => {
          derniereLigne?.scrollIntoView({ behavior: 'smooth' });
        }, 0);
      } else {
        this.lstImages.splice(index, 1, image);
        this.imgSelectionId = -1;
      }
      observer.next();
      observer.complete();
    })
  }

  /**
   * Renvoie le dernier id comme si on a cliqué dessus
   * @returns ApiImage.id
   */
  clicSurDernierElement() {
    if(this.lstImages.length > 0) {
      this.imgSelectionId = this.lstImages[this.lstImages.length - 1].id;
      this.onElementClick.emit(this.lstImages[this.lstImages.length - 1].id);
    }
  }

}

import { Injectable } from '@angular/core';
import { DialoguesService } from '../components/dialogues/dialogues.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ErreursService {

  constructor(
    private dialoguesService: DialoguesService
  ) { }

  erreurApi(titre: string, err: any, router: Router, callback: Function) {
    if(err.status == 0) {
      this.dialoguesService.messageBox(
        titre, 
        "Le serveur API semble hors-ligne", 
        () => {
          router.navigate(["login"]);
        }, 0
      );
    } else if(err.status == 400) {
      this.dialoguesService.messageBox(
        titre, 
        "Vous n'êtes pas correctement identifié", 
        () => {
          router.navigate(["login"]);
        }, 0
      );
    } else {
      this.dialoguesService.messageBox(
        titre, 
        "Oups ! Une erreur est survenue: <br>" + err.error.message, 
        () => {
          callback();
        }, 0
      );
    }

  }
}

import { Routes } from '@angular/router';
import { LoginComponent } from './components/pages/login/login.component';
import { PageSaisieStdComponent } from './components/pages/page-saisie-std/page-saisie-std.component';
import { PageChoixSaisieComponent } from './components/pages/page-choix-saisie/page-choix-saisie.component';
import { AppGuard } from './AppGuard';
import { TokenComponent } from './components/pages/login/token/token.component';

export const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full'},

  { path: 'login', component: LoginComponent},

  { path: 'keycloakToken', component: TokenComponent },

  { path: 'choix', 
    canActivate: [AppGuard.canActivate],
    component: PageChoixSaisieComponent
  },

  { path: 'PageSaisieStd', 
    canActivate: [AppGuard.canActivate],
    component: PageSaisieStdComponent
  },

  { 
    path: '**',
    component: LoginComponent
  }

];

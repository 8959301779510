import { Component, ComponentFactoryResolver, ComponentRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DlgParams } from '../dlg-params';
import { CommonModule } from '@angular/common';
import { fromEvent, Subscription } from 'rxjs';

/**
 * Composant générique pour affichage de dialogue modal
 * Les composants Titre, Corps et Boutons sont passés dynamiquement, comme les paramètres
 */
@Component({
  selector: 'app-dlg-generic-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dlg-generic-modal.component.html',
  styleUrls: ['./dlg-generic-modal.component.scss']
})
export class DlgGenericModalComponent implements OnInit {
  @Input({ required: true }) titreComponentType!: Type<any>;
  @Input({ required: true }) corpsComponentType!: Type<any>;
  @Input({ required: true }) btnsComponentType!: Type<any>;
  @Input({ required: true }) dlgParams!: DlgParams;
  @Output() btnActionEventEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() keypressed: EventEmitter<any> = new EventEmitter<any>();


  public componentRef?: ComponentRef<any>;

  @ViewChild('titreComponent', { read: ViewContainerRef, static: true})
  titreComponent!: ViewContainerRef;
  @ViewChild('corpsComponent', { read: ViewContainerRef, static: true})
  corpsComponent!: ViewContainerRef;
  @ViewChild('btnsComponent', { read: ViewContainerRef, static: true})
  btnsComponent!: ViewContainerRef;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    public activeModal: NgbActiveModal,
    private renderer: Renderer2,
  ) { }

  ngOnInit(): void {
    // Chargement des composants dynamiques
    this.titreComponent?.clear();
    let factory = this.componentFactoryResolver.resolveComponentFactory(this.titreComponentType);
    this.componentRef = this.titreComponent.createComponent(factory);
    this.componentRef.instance.dlgParams = this.dlgParams;

    this.corpsComponent.clear();
    factory = this.componentFactoryResolver.resolveComponentFactory(this.corpsComponentType);
    this.componentRef = this.corpsComponent.createComponent(factory);
    this.componentRef.instance.dlgParams = this.dlgParams;

    this.btnsComponent.clear();
    factory = this.componentFactoryResolver.resolveComponentFactory(this.btnsComponentType);
    this.componentRef = this.btnsComponent.createComponent(factory);
    this.componentRef.instance.dlgParams = this.dlgParams;
    this.renderer.addClass(this.componentRef.location.nativeElement, 'w-100');

    // Traitement des évènements des composants fils: click sur boutons
    // event 'close' = fermeture
    //       x = numéro ou nom de bouton cliqué 
    this.componentRef.instance.btnEventEmit.subscribe((event: any) => {
      switch (event) {
      case 'close': // on ferme directement
        if(this.dlgParams.callBackFermerAvant) {
          this.fermer(event);
          // call back après la fermeture
          if (this.dlgParams.closeCallback != null) {
            this.dlgParams.closeCallback();
          }  
        } else {
          // call back avant la fermeture
          if (this.dlgParams.closeCallback != null) {
            this.dlgParams.closeCallback();
          this.fermer(event);
          }
          this.fermer(event);  
        }
        break;
      default:  // on propage
        this.btnActionEventEmit.emit(event);
      }
    });
/*
    this.componentRef.instance.btnActionEventEmit.subscribe((event: any) => {        // call back au clique du bouton 
      if (event.fnAction != null) {
        event.fnAction(event.operation);
      }
    });
    */
  }

  /**
   * Gestion des touches pour le dialogue
   * @param event 
   */
    gererTouches(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        this.fermer("close");  
      } else if (event.key === 'Enter') {
        this.fermer("close");  
      } else if (event.key === '3') {
        this.fermer("close");  
      }
    }
  
  /**
   * Fermeture du dialogue par la croix
   * @param event
   */
  fermer(event: any) {
    this.activeModal.close(event);
  }

}

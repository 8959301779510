<div class="">
  <div class="row zones-saisies">
    <div class="col-md-3"></div>
    <div class="col-md-4 champ-date">
      <label class="libelle">Date de réception</label>
      <input type="text" 
        #inputSaisied
        name="saisie1" 
        class="form-control espacement input-date" 
        autofocus
        tabindex="0"
        mask="d0/M0/00"
        [showMaskTyped] = "true"
        [(ngModel)]="dateSaisie"
        [maxLength]="6"
        inputMaskDirective
        (valider)="onTraiterEntreed($event)"
        (echappe)="onTraiterEchappe($event)"
        (flecheBas)="onFlecheBasd($event)"
        (flecheHaut)="onFlecheHautd($event)"
      />
    </div>
    <div class="col-md-2 champ-heure">
      <label class="libelle">Heure</label>
      <input type="text" 
        #inputSaisieh
        name="saisie2" 
        class="form-control espacement input_heure" 
        autofocus
        tabindex="1"
        mask="Hh:m0"
        [showMaskTyped] = "true"
        [(ngModel)]="heureSaisie"
        [maxLength]="4"
        inputMaskDirective
        (yaSaisie)="yaSaisied()"
        (imageSuivante)="onImageSuivante($event)"
        (valider)="onTraiterEntreeh($event)"
        (echappe)="onTraiterEchappe($event)"
        (slash)="onTraiterSlash($event)"
        (flecheBas)="onFlecheBash($event)"
        (flecheHaut)="onFlecheHauth($event)"
      />
    </div>
  </div>
</div>
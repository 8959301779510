import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, ComponentRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { Identification } from '../../../models/Identification.model';
import { Router } from '@angular/router';
import { BoutonComponent } from "../../bouton/bouton.component";
import { NgbNav, NgbDropdown, NgbDropdownItem, NgbDropdownToggle, NgbDropdownMenu, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { CommonModule, NgFor } from '@angular/common';
import { IdLibelle } from '../../../models/IdLibelle.model';
import { LoginService } from '../../pages/login/login.service';
import { NOM_PROJET, OPERATEURNOM, OPERATEURPRENOM } from '../../../constantes';
import { WebIndexService } from '../../../services/web-index.service';
import { DialoguesService } from '../../dialogues/dialogues.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DlgGenericModalComponent } from '../../dialogues/dlg-generic-modal/dlg-generic-modal.component';
import { DlgTitreComponent } from '../../dialogues/dlg-titre/dlg-titre.component';
import { DlgProfilCorpsComponent } from '../../dialogues/dlg-profil-corps/dlg-profil-corps.component';
import { DlgFermerBoutonsComponent } from '../../dialogues/dlg-fermer-boutons/dlg-fermer-boutons.component';
import { DlgDeuxBoutonsComponent } from '../../dialogues/dlg-deux-boutons/dlg-deux-boutons.component';
import { version } from '../../../../environments/version';
import { environment } from '../../../../environments/environment';

/**
 * Composant entete de page standard, utilisant un composant dynamique pour le titre
 */
@Component({
    selector: 'app-entete',
    standalone: true,
    templateUrl: './entete.component.html',
    styleUrls: ['./entete.component.scss'],
    imports: [
      CommonModule,
      NgFor,
      NgbNav,
      NgbDropdown,
      NgbDropdownItem,
      NgbDropdownToggle,
      NgbDropdownMenu,
      BoutonComponent,
    ]
})
export class EnteteComponent implements AfterViewInit, OnChanges {
  /** Composant chargé d'afficher le titre */
  @Input({required: true}) titreComponent!: Type<any>;
  /** Tableau de paramètres pour le composant titre */
  @Input({required: true}) titreParams: Record<string, unknown> = {};
  /** true si operateur authentifié */
  @Input({required: true}) identifie: boolean = false;
  /** Liste pour construire les boutons à ajouter id + libellé */
  @Input({required: false}) lstBoutons: IdLibelle[] = [];
  /** Passage d'un tableau de paramètres du composant titre à mettre à jour */
  @Output() menuOptionClick: EventEmitter<string> = new EventEmitter();

  @ViewChild('tplTitre', { read: ViewContainerRef })
  tplTitreRef!: ViewContainerRef;

  nomAppli: string = "Indexation";
  identification: Identification;
  libTraitement: String = "";
  prenom: string = "";
  nom: string = "";
  
  /** Référence au composant titre inséré */ 
  titreComponentRef!: ComponentRef<any>;

  constructor(
    private chgDetector: ChangeDetectorRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    //private viewContainerRef: ViewContainerRef,
    private sanitizer: DomSanitizer,
    private loginService: LoginService,
    private webIndexService: WebIndexService,
    private dialoguesService: DialoguesService,
  ) {
    this.nomAppli = NOM_PROJET;
    this.identification = new Identification(
      {nom: sessionStorage.getItem(OPERATEURNOM), prenom: sessionStorage.getItem(OPERATEURPRENOM)}
    );
  }

  ngAfterViewInit() {
    this.tplTitreRef.clear();
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.titreComponent);
    this.titreComponentRef = this.tplTitreRef.createComponent(componentFactory);
    
    for(let cle in this.titreParams) {
      if (this.titreParams.hasOwnProperty(cle)) { // Au cas ou il y a des propriété héritées, mais ce n'est pas le cas ici
        (this.titreComponentRef.instance)[cle] = this.titreParams[cle];
      }    
    }
    this.chgDetector.detectChanges(); // Pour éviter les messages d'erreurs
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['titreParams'] && this.titreComponentRef) {
      for(let cle in this.titreParams) {
        if (this.titreParams.hasOwnProperty(cle)) { // Au cas ou il y a des propriété héritées, mais ce n'est pas le cas ici
          (this.titreComponentRef.instance)[cle] = this.titreParams[cle];
        }    
      }
      this.titreComponentRef.changeDetectorRef.detectChanges(); // Propager la mise à jour
    }
  }

  /**
   * Pour emettre un evenement click sur option
   * @param id id de l'option cliqué
   */
  menuOptionOnClick(id: string) {
    this.menuOptionClick.emit(id);
  }

  // Afficher le profil dans un dialog
  afficherProfil() {
    this.dialoguesService.afficherProfil();
  }

  /**
   * Téléchargement d'un document légal
   * @param type cgu = Conditions générales, pdc = Politique de confidentialité, mle = Mentions légales
   */
  telechargerRgpd(type : String) {
    this.dialoguesService.dialogBoxOuiNon("test dialog",
      "message",
      2,
      (event: any, dlg: NgbModalRef) => {
        switch (event)  {
          case "close":
            dlg.dismiss();
            break;
          case "action1":
            dlg.dismiss();
            break;
          case "action2":
              dlg.dismiss();      
              break;
        }
        dlg.dismiss();
      },
      5000

    )

    //this.rgpdService.telecharger(type).subscribe(
    //  res => {
    //    saveAs(res.body, this.fonctionService.contentDispositionFileName(res));
    //  },
    //  err => {
    //    const erreur = this.apiService.blobToString(err.error);
    //    this.apiService.traiterErreurApiStd(err, this.dlgGenericModalService);
    //  }
    //);
  }

  aPropos() {
    const version = environment.version + " (" + environment.nom + ")";
    let apiVersion: string = "";;
    this.webIndexService.getApiInfo().subscribe({
      error: (err) => {
        apiVersion = "?.?.?"
      },
      next: (res: any) => {
        apiVersion = res.apiVersion;
      },
      complete: () => {
        this.dialoguesService.messageBox(NOM_PROJET,
          "A propos...<br>" +
          "&nbsp;&nbsp;&nbsp;&nbsp;Version de Chèque+ Indexation: " + version + "<br>" +
          "&nbsp;&nbsp;&nbsp;&nbsp;Version de l'api: " + apiVersion + "<br>",
          () => {},
          10000
        )    
      },      
    })

  }

  // Déconnexion
  logout() {
    this.loginService.logout();
  }

  /**
   * Fonction d'extraction des initiales de l'opérateur en cours
   * @returns Initiales
   */
  initiales() {
    if (this.identification.getNom() == undefined) {
      return 'AB';
    } else {
      return this.identification.getPrenom().substring(0, 1) +
          this.identification.getNom().substring(0, 1);
    }
  }

}


  





